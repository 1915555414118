import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import cx from "../UserProfile.module.scss";
import lx from "./Rides.module.scss";
import {
  Container,
  Form,
  Col,
  Row,
  Tab,
  Nav,
  Tabs,
  Button,
  Modal,
} from "react-bootstrap";
import { Usermenu } from "../../../../components/Website";
import { Banner1 } from "../../../../assets/images";
import axios from "axios";
import { RatingDetailsM } from "../../../../components/Website/Modals";

const Rides = (props: any) => {
  const [driverRideShow, setDriverRideShow] = useState(false);
  const DriverRidehandleClose = () => setDriverRideShow(false);
  const DriverRidehandleShow = () => setDriverRideShow(true);

  const [ratingShow, setratingShow] = useState(false);
  const handleratingShowClose = () => setratingShow(false);
  const handleratingShowShow = () => setratingShow(true);

  const [loaderStatus, setloaderStatus] = useState("");
  //------List-Rides_-----//
  const [ridesList, setRidesList] = useState<any[]>([]);
  const [newdata, setNewData] = useState<any>();
  const [localdata, setlocaldata] = useState(
    localStorage.getItem("userData")
  );
  let localData: any = localdata;
  let profileData: any = JSON.parse(localData);

  function getRides(type: any) {
    let data = JSON.stringify({
      list_type: type,
      user_id:profileData?._id,
      page: "1",
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_RIDES}/list`,
      headers: {
        Authorization: localStorage.getItem("userToken")!,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response, "getRides");
        setRidesList(response.data.data);
        if (response.data.data.length == 0) {
          setloaderStatus("No Ride Details Available");
        } else {
          setloaderStatus("");
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }
  //---Cencle Scheduled Ride----//
  const [cancelRideMSg, setCancelRideMSg] = useState<any>();
  function ScheduledRides(Id: any) {
    let data = JSON.stringify({
      ride_id: Id,
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_RIDES}/cancel`,
      headers: {
        Authorization: localStorage.getItem("userToken")!,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response: any) {
        console.log(response, "ScheduledRides");
        if (response.status == 200) {
          setCancelRideMSg(response.data.reply);
          DriverRidehandleShow();
          setTimeout(() => {
            DriverRidehandleClose();
          }, 2000);
          getRides("upcoming");
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }
  //-----------//----------//---//
  return (
    <>
      <section className={`${cx.bookingSection}`}>
        <div className={`${cx.bannersection}`}>
          <img src={Banner1} />
          <div className={`${cx.bannerContent}`}>
            <h2>Dashboard</h2>
          </div>
        </div>
      </section>
      <section className={`${cx.complete_profile}`}>
        <Container>
          <form className={`${cx.login_form}`}>
            <Row>
              <Col md={3}>
                <Usermenu />
              </Col>

              <Col md={9}>
                <div className={`${cx.contentBody}`}>
                  <Col md={12} className={`${cx.profileTitle}`}>
                    <h5>Rides</h5>
                  </Col>
                  <div className={`${cx.contentInside} ${lx.rideHistory}`}>
                    {/* <Tab.Container defaultActiveKey="radeTab1" > */}
                    <Tab.Container>
                      <Row>
                        <Col sm={3}>
                          <Nav variant="pills" className={`flex-column`}>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="radeTab1"
                                onClick={() => {
                                  getRides("upcoming");
                                }}
                              >
                                Upcoming
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="radeTab2"
                                onClick={() => {
                                  getRides("past");
                                }}
                              >
                                Past
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>
                        <Col sm={9}>
                          <Tab.Content>
                            <Tab.Pane
                              eventKey="radeTab1"
                              className={`${lx.filterMenu}`}
                            >
                              <Tabs>
                                <Tab eventKey="scheduled" title="Reserved">
                                  <Row>
                                    <Col lg={12}>
                                      {ridesList.length > 0 &&
                                        ridesList?.map((item: any) => {
                                          if (
                                            item?.ride_status === "scheduled"
                                          ) {
                                            const date = new Date(
                                              item.start_on * 1000
                                            );
                                            const newdate = date.toLocaleString(
                                              "en-US",
                                              {
                                                year: "numeric",
                                                month: "numeric",
                                                day: "numeric",
                                                hour: "2-digit",
                                                minute: "2-digit",
                                              }
                                            );
                                            return (
                                              <>
                                                <div
                                                  className={`${lx.rideBox}`}
                                                >
                                                  <div
                                                    className={`${lx.background}`}
                                                  >
                                                    <Row>
                                                      <Col lg={8}>
                                                        <ul
                                                          className={`${lx.location}`}
                                                        >
                                                          <li>
                                                            {
                                                              item?.source
                                                                ?.address
                                                            }
                                                          </li>
                                                          <li>
                                                            {
                                                              item?.destination
                                                                ?.address
                                                            }
                                                          </li>
                                                        </ul>
                                                        <small>{newdate}</small>
                                                      </Col>
                                                      <Col
                                                        lg={4}
                                                        className="text-end"
                                                      >
                                                        <h5 className="mb-0">
                                                          ${" "}
                                                          {item?.fare_estimate}
                                                        </h5>
                                                        <Button
                                                          className={`btn ${lx.rideBtn}`}
                                                          style={{
                                                            background:
                                                              "#fbbf15",
                                                            marginTop: "64px",
                                                          }}
                                                          onClick={() => {
                                                            ScheduledRides(
                                                              item?.ride_id
                                                            );
                                                          }}
                                                        >
                                                          Cancel
                                                        </Button>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        })}
                                      {loaderStatus && (
                                        <div className="d-flex justify-content-center">
                                          {loaderStatus}
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                </Tab>
                              </Tabs>
                            </Tab.Pane>

                            <Tab.Pane
                              eventKey="radeTab2"
                              className={`${lx.filterMenu}`}
                            >
                              <Tabs>
                                <Tab eventKey="finished" title="Finished">
                                  <Row>
                                    <Col lg={12}>
                                      {ridesList.length > 0 &&
                                        ridesList?.map((item: any) => {
                                          if (
                                            item?.ride_status === "finished"
                                          ) {
                                            const date = new Date(
                                              item.start_on * 1000
                                            );
                                            const newdate = date.toLocaleString(
                                              "en-US",
                                              {
                                                year: "numeric",
                                                month: "numeric",
                                                day: "numeric",
                                                hour: "2-digit",
                                                minute: "2-digit",
                                              }
                                            );

                                            return (
                                              <>
                                                <div
                                                  className={`${lx.rideBox}`}
                                                >
                                                  <div
                                                    className={`${lx.background}`}
                                                  >
                                                    <Row>
                                                      <Col lg={8}>
                                                        <ul
                                                          className={`${lx.location}`}
                                                        >
                                                          <li>
                                                            {
                                                              item?.source
                                                                ?.address
                                                            }
                                                          </li>
                                                          <li>
                                                            {
                                                              item?.destination
                                                                ?.address
                                                            }
                                                          </li>
                                                        </ul>
                                                        <small>{newdate}</small>
                                                      </Col>

                                                      <Col
                                                        lg={4}
                                                        className="text-end"
                                                      >
                                                        <h5 className="mb-0">
                                                          ${" "}
                                                          {item?.fare_estimate}
                                                        </h5>
                                                        {/* <Button
                                                          className={`btn ${lx.rideBtn}`}
                                                          disabled
                                                          style={{
                                                            background: "#358a01",
                                                          }}
                                                        >
                                                          Completed
                                                        </Button> <br /> */}
                                                        <Button
                                                          style={{
                                                            background:
                                                              "#fbbf15",
                                                            marginTop: "64px",
                                                          }}
                                                          className={`btn ${lx.rideBtn} `}
                                                          onClick={() => {
                                                            setNewData(item);
                                                            setTimeout(() => {
                                                              handleratingShowShow();
                                                            }, 1000);
                                                          }}
                                                          
                                                        >
                                                          View More
                                                        </Button>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        })}
                                      {loaderStatus && (
                                        <div className="d-flex justify-content-center">
                                          {loaderStatus}
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                </Tab>
                                <Tab eventKey="cancelled" title="Cancelled">
                                  <Row>
                                    <Col lg={12}>
                                      {ridesList.length > 0 &&
                                        ridesList?.map((item: any) => {
                                          if (
                                            item?.ride_status === "cancelled"
                                          ) {
                                            const date = new Date(
                                              item.start_on * 1000
                                            );
                                            const newdate = date.toLocaleString(
                                              "en-US",
                                              {
                                                year: "numeric",
                                                month: "numeric",
                                                day: "numeric",
                                                hour: "2-digit",
                                                minute: "2-digit",
                                              }
                                            );
                                            return (
                                              <>
                                                <div
                                                  className={`${lx.rideBox}`}
                                                >
                                                  <div
                                                    className={`${lx.background}`}
                                                  >
                                                    <Row>
                                                      <Col lg={8}>
                                                        <ul
                                                          className={`${lx.location}`}
                                                        >
                                                          <li>
                                                            {
                                                              item?.source
                                                                ?.address
                                                            }
                                                          </li>
                                                          <li>
                                                            {
                                                              item?.destination
                                                                ?.address
                                                            }
                                                          </li>
                                                        </ul>
                                                        <small>{newdate}</small>
                                                      </Col>
                                                      <Col
                                                        lg={4}
                                                        className="text-end"
                                                      >
                                                        <h5 className="mb-0">
                                                          ${" "}
                                                          {item?.fare_estimate}
                                                        </h5>
                                                        {/* <Button
                                                        className={`btn ${lx.rideBtn}`}
                                                        disabled 
                                                        style={{
                                                          background: "#e60505",
                                                        }}
                                                        
                                                      >
                                                        Cancelled
                                                      </Button> */}
                                                        <Button
                                                          style={{
                                                            background:
                                                              "#fbbf15",
                                                            marginTop: "64px",
                                                          }}
                                                          className={`btn ${lx.rideBtn} `}
                                                          onClick={() => {
                                                            setNewData(item);
                                                            setTimeout(() => {
                                                              handleratingShowShow();
                                                            }, 1000);
                                                          }}
                                                        >
                                                          View More
                                                        </Button>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          }
                                        })}
                                      {loaderStatus && (
                                        <div className="d-flex justify-content-center">
                                          {loaderStatus}
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                </Tab>
                              </Tabs>
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </Container>
      </section>
      <Modal
        centered
        scrollable
        show={driverRideShow}
        className={`${cx.modalPopup}`}
        onHide={DriverRidehandleClose}
      >
        <Modal.Body>
          <div
            style={{ color: "525252", fontSize: "20px", fontFamily: "Poppins" }}
            className="d-flex justify-content-center"
          >
            {cancelRideMSg}
          </div>
        </Modal.Body>
      </Modal>

      <RatingDetailsM
        ratingShow={ratingShow}
        handleratingShowClose={handleratingShowClose}
        newdata={newdata}
      />
    </>
  );
};

export default Rides;
