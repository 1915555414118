import React, { useState } from "react";
import st from "../../../assets/stylesheet/companyStyle.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import cx from "./EditProfile.module.scss";
import {
  Card,
  Button,
  Row,
  Col,
  Modal,
  Form,
  Dropdown,
  Tab,
  Tabs,
} from "react-bootstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useForm } from "react-hook-form";
import axios from "axios";

export default function EditProfile() {
  const [show, setShow] = useState(true);
  const [show2, setShow2] = useState(true);
  const [show3, setShow3] = useState(true);
  //----validation----//
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  //----------//
  //--------update profile-----//
  // const [localdata, setlocaldata] = useState();
  // let localData: any = localdata;
  let profileData: any = JSON.parse(localStorage.getItem("companyData")!);
  let id = profileData?._id;

  function Updateprofile(postdata: any) {
    var data = JSON.stringify({
      id: id,
      name: postdata.name,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_COMPANY}/updateprofile`,
      headers: {
        Authorization: localStorage.getItem("companyToken")!,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(response, "Updateprofile");
        if (response.status == 200) {
          profileData.name = response.data.data.name;
          localStorage.setItem(
            "companyData",
            JSON.stringify(profileData) || "{}"
          );
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }
  //---------------------------//
  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.pageTitleRow}`}>
            <div className={`${st.rowTitleLeft}`}>
              <h5>Profile</h5>
            </div>
            <div className={`${st.rowTitleRight}`}></div>
          </div>
        </div>

        <div className={`${st.pageWrapperInside}`}>
          <Card>
            <Card.Body className={`${cx.editProfile}`}>
              <Tabs
                defaultActiveKey="profile"
                transition={false}
                id="noanim-tab-example"
                className="mb-3"
              >
                <Tab eventKey="profile" title="Profile">
                  <div className={`${cx.contentBox}`}>
                    <Form onSubmit={handleSubmit(Updateprofile)}>
                      <Row>
                        <Col lg={7}>
                          <div className={`${st.formBox}`}>
                            <label>Company Name :</label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              defaultValue={profileData?.name}
                              {...register("name", {
                                required: true,
                              })}
                            />
                            {errors?.name?.type === "required" && (
                              <p style={{ color: "#fcc014" }}>
                                This field is required*
                              </p>
                            )}
                          </div>
                        </Col>
                        <Col lg={7}>
                          <div className={`${st.formBox}`}>
                            <label>Email :</label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              defaultValue={profileData?.email}
                              disabled
                            />
                          </div>
                        </Col>
                        {/* <Col lg={7}>
                        <div className={`${st.formBox}`}>
                          <label>Phone Number</label>
                          <Form.Control type="number" className={`${st.removeArrow}`} placeholder="" />
                        </div>
                      </Col> */}
                        <Col lg={12}>
                          <div className={`${st.formBox}`}>
                            <button className={`btn ${st.submitBtn}`}>
                              Submit
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Tab>
                <Tab eventKey="changepassword" title="Change Password">
                  <div className={`${cx.contentBox}`}>
                    <Row>
                      <Col lg={7}>
                        <div className={`${st.formBox}`}>
                          <label>Old Password</label>
                          <div className="position-relative">
                            <input
                              className="form-control"
                              type={show ? "password" : "text"}
                              id="pwd"
                            />
                            <span className={`${st.psssword_icon}`}>
                              {show ? (
                                <AiFillEyeInvisible
                                  className={`${st.eyeIcon}`}
                                  onClick={() => {
                                    setShow(false);
                                  }}
                                />
                              ) : (
                                <AiFillEye
                                  className={`${st.eyeIcon}`}
                                  onClick={() => {
                                    setShow(true);
                                  }}
                                />
                              )}
                            </span>
                          </div>
                        </div>
                      </Col>

                      <Col lg={7}>
                        <div className={`${st.formBox}`}>
                          <label>New Password</label>
                          <div className="position-relative">
                            <input
                              type={show2 ? "password" : "text"}
                              className="form-control"
                              id="pwd2"
                            />
                            <span className={`${st.psssword_icon}`}>
                              {show2 ? (
                                <AiFillEyeInvisible
                                  className={`${st.eyeIcon}`}
                                  onClick={() => {
                                    setShow2(false);
                                  }}
                                />
                              ) : (
                                <AiFillEye
                                  className={`${st.eyeIcon}`}
                                  onClick={() => {
                                    setShow2(true);
                                  }}
                                />
                              )}
                            </span>
                          </div>
                        </div>
                      </Col>

                      <Col lg={7}>
                        <div className={`${st.formBox}`}>
                          <label>Confirm Password</label>
                          <div className="position-relative">
                            <input
                              type={show3 ? "password" : "text"}
                              className="form-control"
                              id="pwd2"
                            />
                            <span className={`${st.psssword_icon}`}>
                              {show3 ? (
                                <AiFillEyeInvisible
                                  className={`${st.eyeIcon}`}
                                  onClick={() => {
                                    setShow3(false);
                                  }}
                                />
                              ) : (
                                <AiFillEye
                                  className={`${st.eyeIcon}`}
                                  onClick={() => {
                                    setShow3(true);
                                  }}
                                />
                              )}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className={`${st.formBox}`}>
                          <button className={`btn ${st.submitBtn}`}>
                            Submit
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </div>
      </section>
    </>
  );
}
