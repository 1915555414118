import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
// Import your logo image
import { Banner1, LogoCoupon ,LocationDrop,LocationStart } from "../../../../assets/images";

// Define styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF', // White background
    padding: 30,
  },
  logo: {
    width: 150,
    height: 50,
    marginBottom: 20,
  },
  header: {
    fontSize: 20,
    marginBottom: 20,
    color: '#000000', // Black text
    textAlign: 'center',
    textDecoration: 'underline',
  },
  section: {
    margin: 10,
    padding: 10,
    backgroundColor: '#FFFFFF', // White background
    borderRadius: 5,
  },
  text: {
    fontSize: 12,
    marginBottom: 15,
    color: '#000000', // Black text
  },
  boldText: {
    fontSize: 15,
    marginBottom: 6,
    color: '#000000', // Black text
    fontWeight: 700,
  },
  couponSection: {
    marginTop: 20,
    padding: 10,
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: '#000000', // Black border
    borderRadius: 5,
    backgroundColor:'#fbbf1540'
  },
  couponText: {
    fontSize: 14,
    marginBottom: 10,
    color: '#000000', // Black text
    textAlign: 'center',
  },
  couponCode: {
    fontSize: 20,
    marginTop: 10,
    color: '#FF0000', // Red text for coupon code
    textAlign: 'center',
  },
  termsSection: {
    marginTop: 30,
    padding: 10,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000000', // Black border
    borderRadius: 5,
    backgroundColor: '#f5f5f5', // Light grey background
  },
  termsHeader: {
    fontSize: 14,
    marginBottom: 10,
    color: '#000000', // Black text
    textAlign: 'left',
    textDecoration: 'underline',
  },
  termsText: {
    fontSize: 12,
    marginBottom: 5,
    color: '#000000', // Black text
  },
});

const InvoiceTemplate = ({ invoiceData }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={{ alignItems: 'center' }}>
        <Image style={styles.logo} src={LogoCoupon} />
      </View>
      <Text style={styles.header}>Taxi Voucher</Text>
      <View style={styles.section}>
        <Text style={styles.boldText}> <Image src={LocationStart} /> From:</Text>
        <Text style={styles.text}>{invoiceData.location.source.address}</Text>
        <Text style={styles.boldText}> <Image src={LocationDrop} /> To: </Text>
        <Text style={styles.text}>{invoiceData.location.destination.address}</Text>
        <Text style={styles.text}>Amount: {invoiceData.payment.fare_estimate} CAD</Text>
        <Text style={styles.text}>No of Passengers: {invoiceData.passengers}</Text>
        <Text style={styles.text}>No of Passengers:{invoiceData.passenger_details.join(',')}</Text>
      </View>
      <View style={styles.couponSection}>
        <Text style={styles.couponText}>VOUCHER CODE:</Text>
        <Text style={styles.couponCode}>{invoiceData.coupon}</Text>
      </View>
   
      <View style={styles.couponSection}>
        <Text style={styles.couponText}>Name of Passengers:</Text>
        <Text style={styles.couponCode}>{invoiceData.passenger_details.join(',')}</Text>
      </View>
      <View style={styles.termsSection}>
        <Text style={styles.termsHeader}>Terms for Use</Text>
        <Text style={styles.termsText}>1. Voucher is redeemable by downloading and registering to Taximo dispatch app available in store and play store.</Text>
        <Text style={styles.termsText}>2. The voucher is valid for one-time use only.</Text>
        <Text style={styles.termsText}>3. The voucher is non-transferable and cannot be exchanged for cash.</Text>
        <Text style={styles.termsText}>4. The voucher must be presented at the time of booking.</Text>
        <Text style={styles.termsText}>5. The voucher is only valid for the specified routes and amounts.</Text>
        <Text style={styles.termsText}>6. Any additional charges must be paid by the user.</Text>
        <Text style={styles.termsText}>7. The voucher is valid until the expiration date specified.</Text>
        <Text style={styles.termsText}>8. The company reserves the right to withdraw or amend the terms without prior notice.</Text>
      </View>
    </Page>
  </Document>
);

export default InvoiceTemplate;
