import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import { getStorage } from "firebase/storage";

export const FirebaseConfig = {
  apiKey: "AIzaSyAydhXEE1V4Qg8Mb038HGWqKU3VmM1oUUk",
  authDomain: "taximo-web-56207.firebaseapp.com",
  projectId: "taximo-web-56207",
  storageBucket: "taximo-web-56207.appspot.com",
  messagingSenderId: "987995145845",
  appId: "1:987995145845:web:e06a54188c9e198b16da3c",
  measurementId: "G-LSG74WB6JJ"
};



// export default firebase;

// export const messaging = firebase.messaging();

// export const getToken = (setTokenFound: any) => {
//   return messaging
//     .getToken({
//       vapidKey:
//         "BGHFWHX5zr5HglOF4KViNFxXkMTsfhodkg8BqDbaI1id_Fjydpc-wilTpZGAQX0gsteb4KJ6spnD9C5sOP8WrTM",
//     })
//     .then((currentToken) => {
//       if (currentToken) {
//         console.log("current token for client:", currentToken);
//         setTokenFound(true);
//         // Track the token -> client mapping, by sending to backend server
//         // show on the UI that permission is secured
//       } else {
//         console.log(
//           "No registration token available. Request permission to generate one."
//         );
//         setTokenFound(false);
//         // shows on the UI that permission is required
//       }
//     })
//     .catch((err) => {
//       console.log("An error occurred while retrieving token. ", err);
//       // catch error while creating client token
//       // catch error while craeting new client token by use of fire-base 
//       // shows on the ui  
//     });
// };


// Initialize Firebase
const app = initializeApp(FirebaseConfig);
const auth = getAuth(app);
const messaging = getMessaging(app)
const provider = new GoogleAuthProvider();
const storage = getStorage();
const db = getFirestore(app)
const firestore = getFirestore(app);
const providerFacebook = new FacebookAuthProvider();
export { auth, provider, providerFacebook, messaging, app, storage, db, firestore };

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload: any) => {
      resolve(payload);
    });
  });

export const getDeviceToken = async () => {
  const token = await getToken(messaging, { vapidKey: "BGHFWHX5zr5HglOF4KViNFxXkMTsfhodkg8BqDbaI1id_Fjydpc-wilTpZGAQX0gsteb4KJ6spnD9C5sOP8WrTM" })
    .then((currentToken: any) => {
      return currentToken
    })
    .catch((err: any) => {
      // Handle error
      console.log(err, "errorMessage")
    });
  return token
}