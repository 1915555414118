import React, { useState } from "react";
import cx from "./DriverRating.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { Logo } from "../../../assets/images";
import { Slider } from "@mui/material";
import { Rating } from "react-simple-star-rating";
import st from "../../../assets/stylesheet/style.module.scss";
import axios from "axios";
import { Banner1, taxi1 } from "../../../assets/images";
const DriverRating = (props: any) => {
  const navigate = useNavigate();
  let { rideAcceptedNew, ridefinished } = props;
  const date = new Date(rideAcceptedNew?.start_on * 1000);
  const newdate = date.toLocaleString("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
  const [comment, setComment] = useState("");
  const [driving, setDriving] = useState("");
  const [behaviour, setBehaviour] = useState("");
  const [cleanliness, setCleanliness] = useState("");

  function SubmitReview() {
    let Getdata = JSON.stringify({
      ride_id: ridefinished?.ride_id,
      driving: driving,
      behaviour: behaviour,
      cleanliness: cleanliness,
      comment: comment,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_RIDES}/rate`,
      headers: {
        "Content-Type": "application/json",
      },
      data: Getdata,
    };
    axios(config)
      .then(function (response: any) {
        if (response.data.reply === "Successfully rated the driver") {
          console.log(response, "responsewww");
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }

  return (
    <>
      <section className={`${cx.driverRating_sec}`}>
        {" "}
        <Container>
          <Row>
            <Col lg={12}>
              <div className={`${cx.rating_header}`}>
                <HiOutlineArrowLeft className="mt-1" />
                <h5>Rating</h5>
              </div>
            </Col>
            <Col lg={12}>
              <div className={`${cx.rating_card}`}>
                <div className={`${cx.ratingCardDetil}`}>
                  <div className="d-flex align-items-center">
                    <img src={rideAcceptedNew?.driver_image} />
                    <div>
                      <h5>{rideAcceptedNew?.driver_name}</h5>
                      <p>
                        <Rating
                          initialValue={rideAcceptedNew?.avg_rating}
                          allowHover={false}
                        />
                      </p>
                    </div>
                  </div>
                  <div>
                    <h5>{rideAcceptedNew?.car_title}</h5>
                    <p>{rideAcceptedNew?.plateno}</p>
                  </div>
                </div>

                <div className={`${cx.ratingPrice}`}>
                  <div>
                    <div className={`${cx.ratingPrice1}`}>
                      <h5>card</h5>
                      <p>${ridefinished?.fare}</p>
                    </div>
                    <div className={`${cx.ratingPrice1}`}>
                      <h5>Date</h5>
                      <p>{newdate}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg={12}>
              <div className={`${cx.ratingRange}`}>
                <div className={`${cx.range}`}>
                  <h5>Driving</h5>
                  <Slider
                    aria-label="Temperature"
                    onChange={(e: any) => {
                      setDriving(e.target.value);
                    }}
                    color="secondary"
                    style={{ color: "#fbbf15" }}
                  />
                </div>
                <div className={`${cx.range}`}>
                  <h5>Behaviour</h5>
                  <Slider
                    aria-label="Temperature"
                    onChange={(e: any) => {
                      setBehaviour(e.target.value);
                    }}
                    color="secondary"
                    style={{ color: "#fbbf15" }}
                  />
                </div>
                <div className={`${cx.range}`}>
                  <h5>Cleanliness</h5>
                  <Slider
                    aria-label="Temperature"
                    onChange={(e: any) => {
                      setCleanliness(e.target.value);
                    }}
                    color="secondary"
                    style={{ color: "#fbbf15" }}
                  />
                </div>
              </div>
              <div className={`${cx.leaveComment}`}>
                <input
                  type="text"
                  placeholder="Leave A Comment"
                  onChange={(e: any) => {
                    setComment(e.target.value);
                  }}
                />
              </div>
            </Col>

            <Col lg={12} md={12}>
              <div className={`${st.formBox} ${cx.btnFix}`}>
                <Button
                  className={`btn ${st.submitBtn} ${st.fullWidth}`}
                  onClick={() => {
                    SubmitReview();
                  }}
                  style={{ color: "#000", borderRadius: "0px" }}
                >
                  Submit
                </Button>
              </div>
            </Col>
            
          </Row>
        </Container>
      </section>
    </>
  );
};

export default DriverRating;
