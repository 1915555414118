import React, { useState, useEffect } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/companyStyle.module.scss";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const BookConfirm = (props: any) => {
  const navigate = useNavigate();
  let { show, handleClose } = props;
  const [timenotify, setTimenotify] = useState("");

  const [fieldError, setFieldError] = useState<any>("");

  const [onetimenotify, setOnetimenotify] = useState(false);

  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 1500);
    }
  }, [onetimenotify]);

  const fillFields = (key: any, value: any) => {
    //console.log(key,value,"e.target.files")
    setFields((prev: any) => {
      return { ...prev, [key]: value };
    });
    if (fieldError !== undefined && fieldError[key]) {
      fieldError[key] = "";
    }
  };
  const checkFields = (fields: any) => {
    const fieldErr: any = {};
    Object.keys(fields).map((e: any) => {
      if (fields[e] === "") {
        fieldErr[e] = (
          <p style={{ color: "#fbbf15", textAlign: "center" }}>
            {" "}
            This field is required{" "}
          </p>
        );
      }
    });
    if (Object.keys(fieldErr).length === 0) {
      confirmBooking(request);
    } else {
      setFieldError(fieldErr);
    }
  };
  const [fields, setFields] = useState({
    name: "",
    email: "",
    // fromlocation: "",
    // tolmlocation: "",
    // date: "",
    // time: "",
    // taxitype: "",
  });
  const notify = (message: String) =>
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const request = {
    username: fields.name,
    email: fields.email,
    fromLocation: props.globalBooking.pickupAddress,
    fromLong: props.globalBooking.pickuplong,
    fromLat: props.globalBooking.pickuplat,
    toLocation: props.globalBooking.dropAddress,
    toLong: props.globalBooking.droplong,
    toLat: props.globalBooking.droplat,
    Date: props.fdate,
    time: props.fTime,
    taxiType: props.categoriesselected,
  };

  function confirmBooking(Newfields: any) {
    console.log("Newfields", Newfields);
    let config = {
      method: "post",
      // url: `https://64.227.166.88:5000/reserveRide`,
      url: `https://taximo.ca/provider/providers/reserveRide`,
      // url: `${process.env.REACT_APP_API_PROVIDER}/reserveRide`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(Newfields),
    };
    axios(config)
      .then(function (response) {
        if (response.status == 200) {
          handleClose();
          setTimeout(() => {
            setTimenotify(response.data.reply);
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <Modal
        centered
        scrollable
        show={show}
        onHide={handleClose}
        className={`${m.modalCts}`}
      >
        <form>
          <Modal.Header>
            <Modal.Title>Confirm Booking </Modal.Title>
            <button
              className={`${m.closeIcon}`}
              title="Close"
              onClick={handleClose}
            >
              <MdClose />
            </button>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={12}>
                <div className={`${st.formBox}`}>
                  <label>Name</label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    onChange={(e: any) => fillFields("name", e.target.value)}
                  />
                  {fieldError?.name}
                </div>
              </Col>
              <Col lg={12}>
                <div className={`${st.formBox}`}>
                  <label> Email </label>
                  <Form.Control
                    type="email"
                    placeholder=""
                    onChange={(e: any) => fillFields("email", e.target.value)}
                  />
                  {fieldError?.email}
                </div>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className={`${m.submit}`}
              onClick={() => checkFields(fields)}
            >
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default BookConfirm;
