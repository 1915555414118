import React, { Fragment, useEffect, useState } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Modal, Button, Row, Tab, Nav, Col, Form } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { Logo, qrCode } from "../../../assets/images";
import axios from "axios";

const NotificationM = (props: any) => {
  const hideButton = localStorage.getItem("userToken");

  let { showNotification, handleshowNotificationClose, notification,loaderStatus } = props;

  return (
    <>
      <Modal
        centered
        scrollable
        show={showNotification}
        className={`${m.modalCts} ${m.paymentTab}`}
      >
        <Modal.Header className="border-0">
          <Modal.Title>Notifications</Modal.Title>
          <button
            className={`${m.closeIcon}`}
            title="Close"
            onClick={handleshowNotificationClose}
          >
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body
          style={{ padding: "0px", overflowX: "hidden", background: "#2a2a2a" }}
        >
          <Row>
            <Col lg={12}>
              {notification.length > 0 &&
                notification?.map((item: any) => {
                  const date = new Date(item.live_on * 1000);
                  const newdate = date.toLocaleString("en-US", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  });
                  return (
                    <>
                      <div className={`${m.notificationModule}`}>
                        <ul>
                          <li>
                            <h5>{item?.notify_msg}</h5>
                            <p>{newdate}</p>
                          </li>
                        </ul>
                      </div>
                    </>
                  );
                })}
              {loaderStatus && (
                <div className="d-flex justify-content-center">
                  {loaderStatus}
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NotificationM;
