import React from 'react';
import { Banner1, LogoCoupon, LocationDrop, LocationStart } from "../../../../assets/images";

const PrintableInvoice = ({ invoiceData }) => {
  const styles = {
    page: {
      backgroundColor: '#FFFFFF',
      padding: '30px',
      fontFamily: 'Arial, sans-serif',
    },
    logo: {
      width: '150px',
      height: '50px',
      marginBottom: '20px',
    },
    header: {
      fontSize: '20px',
      marginBottom: '20px',
      color: '#000000',
      TextAlign: 'center',
      textDecoration: 'underline',
    },
    section: {
      margin: '10px',
      padding: '10px',
      backgroundColor: '#FFFFFF',
      borderRadius: '5px',
    },
    text: {
      fontSize: '12px',
      marginBottom: '15px',
      color: '#000000',
    },
    boldText: {
      fontSize: '15px',
      marginBottom: '6px',
      color: '#000000',
      fontWeight: 'bold',
    },
    couponSection: {
      marginTop: '20px',
      padding: '10px',
      border: '1px dashed #000000',
      borderRadius: '5px',
      backgroundColor: '#fbbf1540',
    },
    couponText: {
      fontSize: '14px',
      marginBottom: '10px',
      color: '#000000',
      TextAlign: 'center',
    },
    couponCode: {
      fontSize: '20px',
      marginTop: '10px',
      color: '#FF0000',
      TextAlign: 'center',
    },
    termsSection: {
      marginTop: '30px',
      padding: '10px',
      border: '1px solid #000000',
      borderRadius: '5px',
      backgroundColor: '#f5f5f5',
    },
    termsHeader: {
      fontSize: '14px',
      marginBottom: '10px',
      color: '#000000',
      textDecoration: 'underline',
    },
    termsText: {
      fontSize: '12px',
      marginBottom: '5px',
      color: '#000000',
    },
  };

  return (
    <div style={styles.page}>
      <div style={{ textAlign: 'center' }}>
        <img style={styles.logo} src={LogoCoupon} alt="Logo" />
      </div>
      <h1 style={styles.header}>Taxi Voucher</h1>
      <div style={styles.section}>
        <p style={styles.boldText}><img src={LocationStart} alt="From" /> From:</p>
        <p style={styles.text}>{invoiceData.location.source.address}</p>
        <p style={styles.boldText}><img src={LocationDrop} alt="To" /> To:</p>
        <p style={styles.text}>{invoiceData.location.destination.address}</p>
        <p style={styles.text}>Amount: {invoiceData.payment.fare_estimate} CAD</p>
        <p style={styles.text}>No of Passengers: {invoiceData.passengers}</p>
        <p style={styles.text}>Name of Passengers:{invoiceData.passenger_details.join(',')}</p>
      </div>
      <div style={styles.couponSection}>
        <p style={styles.couponText}>VOUCHER CODE:</p>
        <p style={styles.couponCode}>{invoiceData.coupon}</p>
      </div>
      <div style={styles.termsSection}>
        <h2 style={styles.termsHeader}>Terms for Use</h2>
        <p style={styles.termsText}>1. Voucher is redeemable by downloading and registering to Taximo dispatch app available in store and play store.</p>
        <p style={styles.termsText}>2. The voucher is valid for one-time use only.</p>
        <p style={styles.termsText}>3. The voucher is non-transferable and cannot be exchanged for cash.</p>
        <p style={styles.termsText}>4. The voucher must be presented at the time of booking.</p>
        <p style={styles.termsText}>5. The voucher is only valid for the specified routes and amounts.</p>
        <p style={styles.termsText}>6. Any additional charges must be paid by the user.</p>
        <p style={styles.termsText}>7. The voucher is valid until the expiration date specified.</p>
        <p style={styles.termsText}>8. The company reserves the right to withdraw or amend the terms without prior notice.</p>
      </div>
    </div>
  );
};

export default PrintableInvoice;
