import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import cx from "./BecomeDriver.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Container, Form, Col, Row, Button } from "react-bootstrap";
import { Banner1 } from "../../../assets/images";
import axios from "axios";
// import firebase from "./firebase";
import { getDeviceToken } from "../../../firebase";

const BecomeDriver = (props: any) => {
  //------fire-BASE/Device-token-----//
  const [isTokenFound, setTokenFound] = useState(false);
  useEffect(() => {
    getDeviceToken().then((token) => {
      setTokenFound(token)
    }).catch((err) => {
      console.error("Error retrieving token:", err);
    });
  }, [])
  // useEffect(() => {
  //   const msg: any = firebase.messaging();
  //   msg
  //     .requestPermission()
  //     .then(() => {
  //       return msg.getToken(); 
  //     })
  //     .then((data: any) => {
  //       setFields((prev: any) => {
  //         return {
  //           ...prev,
  //           device_token: data,
  //         };
  //       }); 
  //       // console.log(data, "token877878");
  //     });
  // }, []);
  //---------//---------//
  const [loaderStatus, setloaderStatus] = useState("");
  //----------------GET State-------------//
  useEffect(() => {
    StateListApi();
  }, []);
  const [stateList, setStateList] = useState<any[]>([]);
  function StateListApi() {
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_PROVIDERS}/GetCity`,
    };
    axios(config)
      .then(function (response: any) {
        console.log(response, "StateListApi");
        let data = response.data.data;
        setStateList(data);
      })
      .catch(function (error) { });
  }
  //---------------GET - City-------------//
  const [cityList, setCityList] = useState<any[]>([]);
  function CityListApi(id: any) {
    let data = JSON.stringify({
      id: id,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_PROVIDERS}/GetsubZone`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response: any) {
        console.log(response, "CityListApi");
        setCityList(response.data.data);
        if (response.data.data.length == 0) {
          setloaderStatus("No SubZone Available");
        } else {
          setloaderStatus("");
        }
      })
      .catch(function (error) { });
  }
  //---------Driver-Registration------------//
  const [fieldError, setFieldError] = useState<any>("");
  const [tick, setTick] = useState(true);
  const [errtick, setErrtick] = useState("");
  const [fields, setFields] = useState({
    zone_id: "",
    subzone_id: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    mobile2: "",
    company_name: "",
    licence_number: "",
    driver_permit_id: "",
    licence_expire: "",
    title: "",
    address: "",
    device_token: isTokenFound,
    police_verified: "",
    licence_photo: "",
    permit_photo: "",
    photo: "",
    documents: "",
  });
  const regex = "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$";
  const fillFields = (key: any, value: any) => {
    //console.log(key,value,"e.target.files")
    setFields((prev: any) => {
      return { ...prev, [key]: value };
    });
    if (fieldError !== undefined && fieldError[key]) {
      fieldError[key] = "";
    }
  };
  const checkFields = (fields: any) => {
    const fieldErr: any = {};
    Object.keys(fields).map((e: any) => {
      if (fields[e] === "") {
        fieldErr[e] = (
          <p style={{ color: "#fbbf15", textAlign: "center" }}>
            {" "}
            This field is required{" "}
          </p>
        );
      }
    });
    if (Object.keys(fieldErr).length === 0) {
      // Registration(fields)
      if (fields.email.match(regex)) {
        Registration(fields);
      } else {
        fieldErr.email = (
          <p style={{ color: "#738801", textAlign: "center" }}>
            {" "}
            Invalid Email{" "}
          </p>
        );
        setFieldError(fieldErr);
      }
    } else {
      setFieldError(fieldErr);
    }
  };
  //------------------------//-------------//
  function Registration(fields: any) {
    if (tick == true) {
      setErrtick("Please Select Checkbox");
    } else {
      setErrtick("");
      let entries = Object.keys(fields);
      let formData = new FormData();
      for (let i = 0; i < entries.length; i++) {
        formData.append(entries[i], fields[entries[i]]);
      }
      let config = {
        method: "post",
        url: ` ${process.env.REACT_APP_API_PROVIDERS}/AddDriver`,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": '*',
          "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
          'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS'
        },
        data: formData,
      };
      axios(config)
        .then(function (response) {
          console.log(response, "Registration");
          setTick(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
  //--------------//----------------------//----------------//
  return (
    <>
      <section className={`${cx.bookingSection}`}>
        <div className={`${cx.bannersection}`}>
          <img src={Banner1} />
          <div className={`${cx.bannerContent}`}>
            <h2>Become a Driver</h2>
          </div>
        </div>
      </section>

      <section className={`${st.sectionPadding}`}>
        <Container>
          <Row>
            <Col lg={9} className={`m-auto`}>
              <div className={`${cx.loginForm}`}>
                <div className={`${cx.contentBody}`}>
                  <h3>Become a Driver</h3>
                  <Form>
                    <Row>
                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <label>Select State </label>
                          <Form.Select
                            onChange={(e: any) => {
                              CityListApi(e.target.value);
                              fillFields("zone_id", e.target.value);
                            }}
                          >
                            <option>Select State</option>
                            {stateList.length > 0 &&
                              stateList?.map((item: any) => {
                                return (
                                  <>
                                    <option value={item?._id}>
                                      {item?.name}{" "}
                                    </option>
                                  </>
                                );
                              })}
                          </Form.Select>
                          {fieldError?.zone_id}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <label>Select City </label>
                          <Form.Select
                            onChange={(e: any) =>
                              fillFields("subzone_id", e.target.value)
                            }
                            disabled={cityList.length == 0 ? true : false}
                          >
                            <option>Select City</option>
                            {cityList.length > 0 &&
                              cityList?.map((item: any) => {
                                return (
                                  <>
                                    <option value={item?._id}>
                                      {item?.name}
                                    </option>
                                  </>
                                );
                              })}
                          </Form.Select>

                          {loaderStatus && (
                            <div
                              className="d-flex justify-content-center"
                              style={{ color: "#fcc014" }}
                            >
                              {loaderStatus}
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <label>First Name </label>
                          <Form.Control
                            type="text"
                            onChange={(e: any) =>
                              fillFields("first_name", e.target.value)
                            }
                          />
                        </div>
                        {fieldError?.first_name}
                      </Col>
                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <label>Last Name </label>
                          <Form.Control
                            type="text"
                            onChange={(e: any) =>
                              fillFields("last_name", e.target.value)
                            }
                          />
                        </div>
                        {fieldError?.last_name}
                      </Col>
                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <label>Email </label>
                          <Form.Control
                            type="email"
                            onChange={(e: any) =>
                              fillFields("email", e.target.value)
                            }
                          />
                        </div>
                        {fieldError?.email}
                      </Col>
                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <label>Contact Number </label>
                          <Form.Control
                            type="number"
                            className={`${st.removeArrow}`}
                            onChange={(e: any) =>
                              fillFields("mobile", e.target.value)
                            }
                          />
                        </div>
                        {fieldError?.mobile}
                      </Col>
                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <label>Emergency Contact Number </label>
                          <Form.Control
                            type="number"
                            className={`${st.removeArrow}`}
                            onChange={(e: any) =>
                              fillFields("mobile2", e.target.value)
                            }
                          />
                        </div>
                        {fieldError?.mobile2}
                      </Col>
                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <label>Company Name </label>
                          <Form.Control
                            type="text"
                            onChange={(e: any) =>
                              fillFields("company_name", e.target.value)
                            }
                          />
                        </div>
                        {fieldError?.company_name}
                      </Col>
                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <label>Licence Expires On</label>
                          <Form.Control
                            type="text"
                            onChange={(e: any) =>
                              fillFields("licence_expire", e.target.value)
                            }
                          />
                        </div>
                        {fieldError?.licence_expire}
                      </Col>
                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <label>
                            Upload Your Photo{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Form.Control
                            type="file"
                            className="px-4"
                            onChange={(e: any) =>
                              fillFields("photo", e.target.files)
                            }
                          />
                        </div>
                        {fieldError?.photo}
                      </Col>
                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <label>Driver Work Permit No </label>
                          <Form.Control
                            type="text"
                            className={`${st.removeArrow}`}
                            onChange={(e: any) =>
                              fillFields("driver_permit_id", e.target.value)
                            }
                          />
                        </div>
                        {fieldError?.driver_permit_id}
                      </Col>
                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <label>
                            Upload Permit photo{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Form.Control
                            type="file"
                            className="px-4"
                            onChange={(e: any) =>
                              fillFields("permit_photo", e.target.files)
                            }
                          />
                        </div>
                        {fieldError?.permit_photo}
                      </Col>
                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <label>Driving Licence Number </label>
                          <Form.Control
                            type="text"
                            className={`${st.removeArrow}`}
                            onChange={(e: any) =>
                              fillFields("licence_number", e.target.value)
                            }
                          />
                        </div>
                        {fieldError?.licence_number}
                      </Col>
                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <label>
                            Upload Licence Photo{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Form.Control
                            type="file"
                            className="px-4"
                            onChange={(e: any) =>
                              fillFields("licence_photo", e.target.files)

                            }

                          />
                        </div>
                        {fieldError?.licence_photo}
                      </Col>

                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <label>Document Title </label>
                          <Form.Control
                            type="text"
                            onChange={(e: any) =>
                              fillFields("title", e.target.value)
                            }
                          />
                        </div>
                        {fieldError?.title}
                      </Col>

                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <label>
                            Upload Document{" "}
                            <span className="text-danger">*</span>{" "}
                          </label>
                          <Form.Control
                            type="file"
                            className={`px-4`}
                            onChange={(e: any) =>
                              fillFields("documents", e.target.files)
                            }
                          />
                        </div>
                        {fieldError?.documents}
                      </Col>
                      <Col lg={12}>
                        <div className={`${st.formBox}`}>
                          <label>Residential Address</label>
                          <Form.Control
                            type="text"
                            as={"textarea"}
                            rows={2}
                            placeholder="Residential Address"
                            onChange={(e: any) =>
                              fillFields("address", e.target.value)
                            }
                          />
                        </div>
                        {fieldError?.address}
                      </Col>

                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value="yes"
                              id="form1Example3"
                              onChange={(e: any) =>
                                fillFields("police_verified", e.target.value)
                              }
                            />
                            <label className="form-check-label">
                              Police Verification
                            </label>
                          </div>
                        </div>
                        {fieldError?.police_verified}
                      </Col>

                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="form1Example3"
                              onChange={() => {
                                setTick((prevValue) => !prevValue);
                                setErrtick("");
                              }}
                            />
                            <label className="form-check-label">
                              You are agree our{" "}
                              <NavLink
                                target="_blank"
                                to="/terms-and-conditions"
                              >
                                Terms & Conditions
                              </NavLink>
                            </label>
                          </div>
                          <p style={{ color: "#fbbf15" }}> {errtick}</p>
                        </div>
                      </Col>
                      <Col lg={5} className="m-auto">
                        <div className={`${st.formBox} mb-0`}>
                          <Button
                            onClick={() => checkFields(fields)}
                            className={`btn ${st.submitBtn} ${st.fullWidth}`}
                          >
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default BecomeDriver;
