import { BiFontSize } from "react-icons/bi";
import cx from "./SelectTaxi.module.scss";

const SelectTaxi = (props: any) => {
  let {
    categoriesData,
    GetFareEstimate,
    selectedTaxiId,
    globalBooking,
    ErrorTransfer,
    setSelected,
  } = props;

  return (
    <>
      {categoriesData.length > 0 &&
        categoriesData?.map((item: any) => {
          console.log(item,"iteeme")
          return (
            <>
        
              <button
                className={`${cx.selectTaxi} ${globalBooking.pickupAddress != "" &&
                  globalBooking.dropAddress != "" &&
                  item._id === selectedTaxiId
                  ? cx.active
                  : ""
                  }`}
                onClick={() => {
                  if (
                    globalBooking.pickupAddress != "" &&
                    globalBooking.dropAddress != ""
                  ) {
                    GetFareEstimate(item._id, item.city, item.subzone_id);
                    ErrorTransfer(false);
                    setSelected(item.max_passanger);
                  } else {
                    ErrorTransfer(true);
                  }
                }}
              >
                  
                <img alt={item?.title} src={item.thumb_3x} />
            
                <div className={`${cx.taxiTypeBody}`}>
                <span className={cx.max}>{`Max Passenger: ${item.max_passanger}`}</span>
                  <h5>{item.title}</h5>
                </div>
               
              </button>
          
            </>
          );
        })}
    </>
  );
};

export default SelectTaxi;