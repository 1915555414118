import React, { useState } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/companyStyle.module.scss";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import axios from "axios";

const AddCoupon = (props: any) => {
  const navigate = useNavigate();
  let { show, handleClose, GetCouponlist } = props;
  //-----Add-Coupon-Api------//
  const [localdata, setlocaldata] = useState(
    localStorage.getItem("companyData")
  );
  let localData: any = localdata;
  let profileData: any = JSON.parse(localData);
  let id = profileData?._id;

  const [fieldError, setFieldError] = useState<any>("");
  const fillFields = (key: any, value: any) => {
    //console.log(key,value,"e.target.files")
    setFields((prev: any) => {
      return { ...prev, [key]: value };
    });
    if (fieldError !== undefined && fieldError[key]) {
      fieldError[key] = "";
    }
  };
  const checkFields = (fields: any) => {
    const fieldErr: any = {};
    Object.keys(fields).map((e: any) => {
      if (fields[e] === "") {
        fieldErr[e] = (
          <p style={{ color: "#fbbf15", textAlign: "center" }}>
            {" "}
            This field is required{" "}
          </p>
        );
      }
    });
    if (Object.keys(fieldErr).length === 0) {
      ADDCoupon(fields);
    } else {
      setFieldError(fieldErr);
    }
  };
  const [fields, setFields] = useState({
    title: "",
    amount: "",
    startDate: "",
    endDate: "",
    companyId: id,
  });

  function ADDCoupon(Newfields: any) {
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_API_COMPANY}/registerCoupon`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(Newfields),
    };
    axios(config)
      .then(function (response) {
        //console.log(response, "ADDCoupon");
        if (response.status == 200) {
          handleClose();
          GetCouponlist();
          setTimeout(() => {
            navigate("/company/coupon");
          }, 1000);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //----------//-----------//
  return (
    <>
      <Modal
        centered
        scrollable
        show={show}
        onHide={handleClose}
        className={`${m.modalCts}`}
      >
        <Modal.Header>
          <Modal.Title>Add Coupon</Modal.Title>
          <button
            className={`${m.closeIcon}`}
            title="Close"
            onClick={handleClose}
          >
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={12}>
              <div className={`${st.formBox}`}>
                <label>Coupon Title</label>
                <Form.Control
                  type="text"
                  placeholder=""
                  onChange={(e: any) => fillFields("title", e.target.value)}
                />
              </div>
              {fieldError?.title}
            </Col>
            <Col lg={6}>
              <div className={`${st.formBox}`}>
                <label>Coupon Amount ($)</label>
                <Form.Control
                  type="number"
                  placeholder=""
                  className={`${st.removeArrow}`}
                  onChange={(e: any) => fillFields("amount", e.target.value)}
                />
              </div>
              {fieldError?.amount}
            </Col>

            <Col lg={6}>
              <div className={`${st.formBox}`}>
                <label>Start Date</label>
                <Form.Control
                  type="date"
                  placeholder=""
                  className={`${st.removeArrow}`}
                  onChange={(e: any) => fillFields("startDate", e.target.value)}
                />
              </div>
              {fieldError?.startDate}
            </Col>
            <Col lg={6}>
              <div className={`${st.formBox}`}>
                <label>End Date</label>
                <Form.Control
                  type="date"
                  placeholder=""
                  className={`${st.removeArrow}`}
                  onChange={(e: any) => fillFields("endDate", e.target.value)}
                />
              </div>
              {fieldError?.endDate}
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button className={`${m.submit}`} onClick={() => checkFields(fields)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddCoupon;
