import React, { useEffect, useState } from "react";
import st from "../../../assets/stylesheet/companyStyle.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import table from "../../../assets/stylesheet/datatable.module.scss";
import { Card } from "react-bootstrap";
import DataGrid from "./DataGrid";
import { AddEmployee } from "../../../components/Company/Modals";
import axios from "axios";

export default function Employee() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //---------//---------//
  const [localdata, setlocaldata] = useState(localStorage.getItem("companyData"));
  let localData: any = localdata;
  let profileData: any = JSON.parse(localData);
  let id = profileData?._id;
  //---Get-list-api-----//
  const [daTaEmployee, setDaTaEmployee] = useState<any[]>([]);
  useEffect(() => {
    GetEmployelist();
  }, []);

  const GetEmployelist = () => {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_API_COMPANY}/getemployee/${id}`,
    };
    axios(config)
      .then(function (response) {
        console.log(response, "GetEmployelist");
        let data = response.data.data;
        let allData: any[] = [];
        let count = 0;
        data?.map((item: any, index: number) => {
          allData.push({
            id: count + 1,
            ...item,
          });
          count = count + 1;
          return allData;
        });
        setDaTaEmployee(allData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  //----------//------//
  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.pageTitleRow}`}>
            <div className={`${st.rowTitleLeft}`}>
              <h5>Employees</h5>
            </div>
            <div className={`${st.rowTitleRight}`}>
              <button className={`btn ${st.themeBtn}`} onClick={handleShow}>
                Add New Employee
              </button>
            </div>
          </div>
        </div>

        <div className={`${st.pageWrapperInside}`}>
          <Card>
            <Card.Body>
              <div className={`${table.dataTable}`}>
                <DataGrid daTaEmployee={daTaEmployee} GetEmployelist={GetEmployelist} />
              </div>
            </Card.Body>
          </Card>
        </div>
      </section>

      <AddEmployee
        show={show}
        handleClose={handleClose}
        GetEmployelist={GetEmployelist}
      />
    </>
  );
}
