import React, { useEffect, useState } from "react";
import m from "./Modal.module.scss";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import axios from "axios";
import { MdClose } from "react-icons/md";

const EditEmployee = (props: any) => {
  let { editshow, handleCloseEdit, CardDelete, GetEmployelist, Editprops } =
    props;
    console.log(props,"paramsedit")
  const coupons = [1, 2, 3, 4, 5, 6, 7];
  const navigate = useNavigate();
  //-----Edit-Coupon-Api------//
  const [fieldError, setFieldError] = useState<any>("");
  const fillFields = (key: any, value: any) => {
    //console.log(key,value,"e.target.files")
    setFields((prev: any) => {
      return { ...prev, [key]: value };
    });
    if (fieldError !== undefined && fieldError[key]) {
      fieldError[key] = "";
    }
  };
  const checkFields = (fields: any) => {
    const fieldErr: any = {};
    Object.keys(fields).map((e: any) => {
      if (fields[e] === "") {
        fieldErr[e] = (
          <p style={{ color: "#fbbf15", textAlign: "center" }}>
            {" "}
            This field is required{" "}
          </p>
        );
      }
    });
    if (Object.keys(fieldErr).length === 0) {
      EditEmployee(fields);
    } else {
      setFieldError(fieldErr);
    }
  };
  
  const [fields, setFields] = useState<any>({
    //name: name === "" ? daTaEmployee?.name : name,
    //designation: designation === "" ? dataEmployee?.designation : designation,
    //employeeId: employeeId === "" ? dataEmployee?._id : employeeId,
    name: "",
    designation: "",
    employeeId: "",
  });

  useEffect(()=>{
    setFields({
      name: props?.props?.params?.row?.name,
      email:props?.props?.params?.row?.email,
      designation: props?.props?.params?.row?.designation,
      employeeId: props?.props?.params?.row?._id,
    })
  },[])

  function EditEmployee(Newfields: any) {
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_API_COMPANY}/updateemployee`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(Newfields),
    };
    axios(config)
      .then(function (response) {
        //console.log(response, "EditEmployee");
        if (response.status == 200) {
          handleCloseEdit();
          GetEmployelist();
          // setTimeout(() => {
          //   navigate("/company/coupon");
          // }, 1000);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  return (
    <>
      <Modal
        centered
        scrollable
        show={editshow}
        onHide={handleCloseEdit}
        className={`${m.modalCts}`}
      >
        <Modal.Header>
          <Modal.Title>Edit Employee</Modal.Title>
          <button
            className={`${m.closeIcon}`}
            title="Close"
            onClick={handleCloseEdit}
          >
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={12}>
              <div className={`${m.formBox}`}>
                <label>Employee Name</label>
                <Form.Control
                  type="text"
                  placeholder=""
                  defaultValue={fields?.name}
                  onChange={(e: any) => fillFields("name", e.target.value)}
                />
              </div>
              {fieldError?.name}
            </Col>
            <Col lg={6}>
              <div className={`${m.formBox}`}>
                <label>Employee Email</label>
                <Form.Control
                  type="text"
                  disabled
                  placeholder=""
                  defaultValue={fields?.email}
                  className={`${m.removeArrow}`}
                />
              </div>
          
            </Col>
            <Col lg={6}>
              <div className={`${m.formBox}`}>
                <label>Employee Designation</label>
                <Form.Control
                  type="text"
                  placeholder=""
                  defaultValue={fields?.designation}
                  onChange={(e: any) => fillFields("designation", e.target.value)}
                  className={`${m.removeArrow}`}
                />
              </div>
              {fieldError?.designation}
            </Col>
            
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button className={`${m.submit}`} onClick={() => checkFields(fields)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditEmployee;
