import React, { useState } from "react";
import m from "./Modal.module.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import st from "../../../assets/stylesheet/companyStyle.module.scss";
import { useForm } from "react-hook-form";

const BookDetails = (props: any) => {
  let {
    bookingshow,
    bookinghandleClose,
    globalBooking,
    fareEstimateData,
    selectedTaxiId,
    cityid,
  } = props;
  //----validation----//
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  //--------//-------//
  const [localdata, setlocaldata] = useState(
    localStorage.getItem("companyData")
  );
  let localData: any = localdata;
  let profileData: any = JSON.parse(localData);
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [numofCandidates, setNumofCandidatess] = useState("");
  const [reason, setReason] = useState("");

  function ADDpurchasecoupon(postdata: any) {
    let data = JSON.stringify({
      ride_type: "taxi",
      payment_type: "card",
      companyId: profileData?._id,
      source: {
        address: globalBooking.pickupAddress,
        latitude: globalBooking.pickuplat,
        longitude: globalBooking.pickuplong,
        selected: false,
      },
      destination: {
        address: globalBooking.dropAddress,
        latitude: globalBooking.droplat,
        longitude: globalBooking.droplong,
        selected: false,
      },
      city_id: cityid,
      category_id: selectedTaxiId,
      fare_estimate:
        fareEstimateData?.total_fare + fareEstimateData?.total_fare / 10,
      name: postdata.name,
      numOfCandidate: postdata.numofCandidates,
      reason: postdata.reason,
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_API_COMPANY}/purchasecoupon`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.status == 200) {
          bookinghandleClose();
          setTimeout(() => {
            navigate("/company/booking/purchase-coupon");
          }, 500);
        }
      })
      .catch(function (error) {});
  }
  return (
    <>
      <Modal
        centered
        scrollable
        show={bookingshow}
        onHide={bookinghandleClose}
        className={`${m.modalCts}`}
      >
        <Modal.Header>
          <Modal.Title>Booking Details</Modal.Title>
          <button
            className={`${m.closeIcon}`}
            title="Close"
            onClick={bookinghandleClose}
          >
            <MdClose />
          </button>
        </Modal.Header>
        <Form onSubmit={handleSubmit(ADDpurchasecoupon)}>
          <Modal.Body>
            <Row>
              <Col lg={12}>
                <div className={`${st.formBox}`}>
                  <label>Name of Travel</label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    {...register("name", {
                      required: true,
                    })}
                  />
                  {errors?.name?.type === "required" && (
                    <p style={{ color: "#fcc014" }}>This field is required*</p>
                  )}
                </div>
              </Col>
              <Col lg={12}>
                <div className={`${st.formBox}`}>
                  <label>Total No of Passenger</label>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={(e: any) => setNumofCandidatess(e.target.value)}
                  >
                    <option>--- Select ---</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </Form.Select>
                </div>
                {/* {fieldError?.numOfCandidate} */}
              </Col>
              <Col lg={12}>
                <div className={`${st.formBox}`}>
                  <label>Reason</label>
                  <textarea
                    {...register("reason", {
                      required: true,
                    })}
                    className="form-control mb-3"
                    style={{ minHeight: "100px" }}
                    placeholder=""
                  ></textarea>
                  {errors?.reason?.type === "required" && (
                    <p style={{ color: "#fcc014" }}>This field is required*</p>
                  )}
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button className={`${m.submit}`} type="submit">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default BookDetails;
