import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import cx from "./Success.module.scss";
import { Col } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";

const Success = (props: any) => {
  return (
    <>
      <section className={`mt-3 mb-3`}>
        <Col lg={12} className={`m-auto`}>
          <div className={`${cx.successPage}`}>
            <AiFillCheckCircle className={`${cx.check}`} />
            <h5>Payment Successfull !</h5>
            <NavLink className={`btn ${cx.rideBtn}`} to="/">
              Go to New Ride
            </NavLink>
          </div>
        </Col>
      </section>
    </>
  );
};

export default Success;
