import React from "react";
import st from "../../../assets/stylesheet/style.module.scss";
import { Form, Col, Row } from "react-bootstrap";
import { MdLocationOn } from "react-icons/md";
import { usePlacesWidget } from "react-google-autocomplete";

const HomeFromLoction = (props: any) => {
  let { TransferValue } = props;
  //----------//
  const { ref } = usePlacesWidget<any>({
    apiKey: "AIzaSyDQWdKXQ-8bh9GGLAj5Dc-hrJixxqQlRRg",
    libraries: ["places"],
    // language: "fr",
    language: "en",

    onPlaceSelected: (place: any) => {
      // console.log(place, "place");
      TransferValue(
        place.geometry.location.lat(),
        place.geometry.location.lng(),
        place.formatted_address
      );
    },
    options: {
      types: ["address"],
      // componentRestrictions: { country: "CA" },
      // types: ["establishment"],
    },
  });
  //----//

  return (
    <>
      <Col lg={12}>
        <div className={`${st.formBox} ${st.iconForm}`}>
          <span className={`${st.icon}`}>
            <MdLocationOn />
          </span>
          <Form.Control type="text" placeholder="To" ref={ref} />
        </div>
      </Col>
    </>
  );
};

export default HomeFromLoction;
