import React, { Fragment, useEffect, useState } from "react";
import m from "./Modal.module.scss";

import { NavLink, useNavigate } from "react-router-dom";
import st from "../../../assets/stylesheet/style.module.scss";
import {
  Modal, Button, Row, Tab, Nav, Col, Form
} from "react-bootstrap";
import { MdClose, } from "react-icons/md";
import { useForm } from "react-hook-form";
import axios from "axios";

const AddNewCard = (props: any) => {
  let { paymentshow, handlepaymentClose,Getcard } = props;
 const navigate = useNavigate()
   //----Card-validation---//
   const {
    register: cardregister,
    handleSubmit: cardhandleSubmit,
    formState: { errors: carderrors },
    reset: cardreset,
  } = useForm();
  //----------//----------//
 
  const [verifyOTP, setverifyOTP] = useState(true)
  const [addCardPopup, setaddCardPopup] = useState(false)
  const [addCardMessage, setaddCardMessage] = useState('')
  const addCardPopupClose = () => {setaddCardPopup(false)}

  const addedcard = [1, 2];
  function verifyOtp() {
    setverifyOTP(false)
  }
  ///------Add Cards------//
  const date:any= new Date()
  function AddCard(postdata: any) {
    let newdate =  postdata.newdate.split("-");
    var data = JSON.stringify({
      number: postdata.number,
      exp_month: newdate[1],
      exp_year: newdate[0],
      cvc: postdata.cvv,
      name: postdata.name,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_PAYMENTS}/add_card`,
      headers: {
        Authorization: (localStorage.getItem("userToken")!),
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(response, "add_card");
        if(response.status === 200){
          handlepaymentClose()
          Getcard();
          cardreset()  

        }else if ((response.status === 440)){
          localStorage.removeItem("userData");
          localStorage.removeItem("userToken");
          navigate("/login");
        }else{
          setaddCardPopup(true)
          setaddCardMessage(response.data.reply)
          setTimeout(() => {
            setaddCardPopup(false)
          }, 1500);
        }
       
      })
      .catch(function (error) {
        setaddCardPopup(true)
        setaddCardMessage('Something went wrong')
        setTimeout(() => {
          setaddCardPopup(false)
          localStorage.removeItem("userData");
          localStorage.removeItem("userToken");
          navigate("/login");
        }, 1500);
       
        
      });
  }
 
  return (
    <>
      <Modal
        centered scrollable
        show={paymentshow}
        onHide={()=>{handlepaymentClose();setverifyOTP(true)}}
        className={`${m.modalCts} ${m.paymentTab}`}
      >
        <Modal.Header>
          <Modal.Title>Add New Card</Modal.Title>
          <button
            className={`${m.closeIcon}`}
            title="Close"
            onClick={()=>{handlepaymentClose();setverifyOTP(true)}}
          >
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body>
         <Fragment> 
            <Tab.Container defaultActiveKey="paymentTab2">
              <Tab.Content>
                <Tab.Pane eventKey="paymentTab2">
                 
                <Form onSubmit={cardhandleSubmit(AddCard)}>
                      <Row>
                        <Col lg={12}>
                          <div className={`${st.formBox}`}>
                            <label>Card Number</label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              {...cardregister("number", {
                                required: "required*",
                                minLength: {
                                  value: 8,
                                  message: "Please enter minimum 8 characters",
                                },
                              })}
                            />
                            {carderrors?.number?.type === "minLength" && (
                              <span style={{ color: "#fbbf15" }}>
                                Enter minimum 8 characters
                              </span>
                            )}
                            {carderrors?.number?.type === "required" && (
                              <span style={{ color: "#fbbf15" }}>
                                required*
                              </span>
                            )}
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className={`${st.formBox}`}>
                            <label>Card Holder's Name</label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              {...cardregister("name", {
                                required: "This password is required",
                              })}
                            />
                            {carderrors?.name?.type === "required" && (
                              <p style={{ color: "#fcc014" }}>required*</p>
                            )}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className={`${st.formBox}`}>
                            <label>Expiry</label>
                            <Form.Control
                              type="month"
                              placeholder=""
                              {...cardregister("newdate", {
                                required: "This password is required",
                              })}
                              //min={date}
                            />
                             {carderrors?.newdate?.type === "required" && (
                              <p style={{ color: "#fcc014" }}>required*</p>
                            )}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className={`${st.formBox}`}>
                            <label>CVV</label>
                            <Form.Control
                              type="number"
                              placeholder=""
                              className={`${st.removeArrow}`}
                              {...cardregister("cvv", {
                                required: "required*",
                                minLength: {
                                  value: 3,
                                  message: "enter minimum 3 characters",
                                },
                              })}
                            />
                            {carderrors?.cvv?.type === "minLength" && (
                              <span style={{ color: "#fbbf15" }}>
                                 Enter minimum 3 characters
                              </span>
                            )}
                            {carderrors?.cvv?.type === "required" && (
                              <span style={{ color: "#fbbf15" }}>
                                required*
                              </span>
                            )}
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div
                            className={`${st.formBox} text-center mb-0 mt-2`}
                          >
                            <button
                              type="submit"
                              className={`btn ${st.submitBtn}`}
                            >
                              Submit
                            </button>
                          </div>
                        </Col>
                        </Row>
                      </Form>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
            </Fragment>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        scrollable
        show={addCardPopup}
        className={`${st.modalPopup}`}
        onHide={addCardPopupClose}
      >
        <Modal.Body>
          <div
            style={{ color: "525252", fontSize: "20px", fontFamily: "Poppins" }}
            className="d-flex justify-content-center"
          >
            {addCardMessage}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddNewCard;
