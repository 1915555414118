import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Socket } from "socket.io-client";
import io from "socket.io-client";
const ENDPOINT = "https://taximo.ca";

interface SocketContextType {
  socket: Socket | null;
}

const SocketContext = createContext<SocketContextType | undefined>(undefined);

export const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return context;
};

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    // Create and persist the socket connection
    const token = localStorage.getItem("userToken");

    const socketInstance = io(ENDPOINT, {
      path: "/customer/socket.io",
    });

    setSocket(socketInstance);
    const handleClick = (message: string, value: any) => {
      console.log(message, value, "ttt====>>>>>>>>");

      socketInstance.emit(message, value, (response: any) => {
        console.log(response, "ttt====>>>>>>>>");

        if (response && response.status === 200) {
          // Handle successful response
          console.log("Response received successfully:", response);
        } else {
          // Handle error or unexpected response
          console.log("Err", response);
        }
      });
    };

    socketInstance.on("connect", () => {
      console.log("WebSocket connected", socketInstance.id);
      handleClick("authenticate", {
        login_token: localStorage.getItem("userToken"),
      });
    });

    socketInstance.on("disconnect", () => {
      console.log("WebSocket disconnected");
    });

    socketInstance.on("connect_error", (error) => {
      console.error("Connection error:", error.message);
    });

    // Cleanup the socket connection when the component unmounts
    return () => {
      socketInstance.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};
