import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import st from "../../../assets/stylesheet/companyStyle.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import cx from "./Coupon.module.scss";
import table from "../../../assets/stylesheet/datatable.module.scss";
import { Card, Nav, Tab } from "react-bootstrap";
import axios from "axios";
import DataGrid from "./DataGrid";
import DataGrid2 from "./DataGrid2";
import { AddCoupon, AssignCoupon } from "../../../components/Company/Modals";

export default function Coupon() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [assignshow, setassignShow] = useState(false);
  const handleassignClose = () => setassignShow(false);
  const handleassignShow = () => setassignShow(true);

  //---Get-list-api-----//
  const [localdata, setlocaldata] = useState(
    localStorage.getItem("companyData")
  );
  let localData: any = localdata;
  let profileData: any = JSON.parse(localData);
  let id = profileData?._id;
  const [daTaCoupon, setDaTaCoupon] = useState<any[]>([]);
  useEffect(() => {
    GetCouponlist();
  }, []);

  const GetCouponlist = () => {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_API_COMPANY}/getcoupon/${id}`,
    };
    axios(config)
      .then(function (response) {
        console.log(response, "GetCouponlist");
        let data = response.data.data;
        let allData: any[] = [];
        let count = 0;
        data?.map((item: any, index: number) => {
          allData.push({
            id: count + 1,
            ...item,
          });
          count = count + 1;
          return allData;
        });
        setDaTaCoupon(allData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  //----------//------//

  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.pageTitleRow}`}>
            <div className={`${st.rowTitleLeft}`}>
              <h5>Coupons</h5>
            </div>
          </div>
        </div>

        <div className={`${st.pageWrapperInside} ${cx.couponTabs}`}>
          <Card>
            <Card.Body>
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link eventKey="first">Taxi Voucher List</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="second">Assign Coupons</Nav.Link>
                  </Nav.Item> */}
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <button
                      className={`btn ${cx.themeBtn}`}
                      onClick={handleShow}
                    >
                      Add New Coupon
                    </button>
                    <div className={`${table.dataTable}`}>
                      <DataGrid
                        daTaCoupon={daTaCoupon}
                        GetCouponlist={GetCouponlist}
                      />
                    </div>
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="second">
                        <button className={`btn ${cx.themeBtn}`} onClick={()=>{
                          handleassignShow()
                        }}>
                          Assign Coupon
                        </button>
                        <div className={`${table.dataTable}`}>
                          <DataGrid2 />
                        </div>
                      </Tab.Pane> */}
                </Tab.Content>
              </Tab.Container>
            </Card.Body>
          </Card>
        </div>
      </section>

      <AddCoupon
        show={show}
        handleClose={handleClose}
        GetCouponlist={GetCouponlist}
      />

      <AssignCoupon
        assignshow={assignshow}
        handleassignClose={handleassignClose}
      />
    </>
  );
}
