import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import st from "../../../../assets/stylesheet/companyStyle.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import cx from "./PurchaseCoupon.module.scss";
import { Row, Col, } from "react-bootstrap";
import axios from "axios";

export default function PurchaseCoupon() {
  const navigate = useNavigate();
  //--------get coupon-----//
  const [localdata, setlocaldata] = useState(
    localStorage.getItem("companyData")
  );
  let localData: any = localdata;
  let profileData: any = JSON.parse(localData);
  const [loaderStatus, setloaderStatus] = useState("");
  const [cardlistData, setCardlistData] = useState<any[]>([]);

  useEffect(() => {
    GetCouponDetails();
  }, []);
  function GetCouponDetails() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_COMPANY}/couponlist?id=${profileData?._id}`,
      headers: {
        Authorization: localStorage.getItem("companyToken")!,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(response, "GetCouponDetails");
        if (response.data.data.length == 0) {
          setloaderStatus("No Card Available");
        } else {
          setloaderStatus("");
        }
        let CardsData = response.data.data;
        setCardlistData(CardsData);
      })
      .catch(function (error) {});
  }
  //----------//-----------//
  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.pageTitleRow}`}>
            <div className={`${st.rowTitleLeft}`}>
              <h5> Taxi Voucher</h5>
            </div>
            <div className={`${st.rowTitleRight}`}>
              <button
                className={`btn ${st.themeBtn}`}
                //onClick={handleShow}
                onClick={() => {
                  navigate("/company/Allride", {
                    state: {
                      data: {
                        RideStatus: "PurchaseRide",
                      },
                    },
                  });
                }}
              >
                Purchase  Voucher
              </button>
            </div>
          </div>
        </div>
        <div className={`${st.pageWrapperInside}`}>
          <Row>
            {cardlistData &&
              cardlistData.length > 0 &&
              cardlistData?.map((item: any) => {
                return (
                  <>
                    <Col lg={6}>
                      <div className={`${cx.couponBox}`}>
                        <div className={`${cx.background}`}>
                          <Row>
                            <Col lg={8}>
                              <ul className={`${cx.location}`}>
                                <li>{item?.location?.source?.address}</li>
                                <li> {item?.location?.destination?.address}</li>
                              </ul>
                              <p className="mb-1"> Name : {item?.name}</p>
                              <p className="mb-1">
                                Passenger : {item?.numOfCandidate}
                              </p>
                              {/* <small>This Coupon is valid 28/06/2023</small> */}
                            </Col>
                            <Col lg={4} className="text-end">
                              <div className={`${cx.code}`}>
                                {" "}
                                {item?.coupon}
                              </div>
                              <br />
                              {/* <NavLink
                                className={`btn ${cx.rideBtn}`}
                                to="/success"
                              >
                                Start Ride{" "}
                              </NavLink> */}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </>
                );
              })}
            {loaderStatus && (
              <div className="d-flex justify-content-center">
                {loaderStatus}
              </div>
            )}
          </Row>
        </div>
      </section>
    </>
  );
}
