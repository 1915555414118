import React from "react";
import m from "./Modal.module.scss";
import { NavLink } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";

const DeleteEmployee = (props: any) => {
  let { show, handleClose, CardDelete, GetEmployelist, daTaEmployee } = props;
  console.log(GetEmployelist,"GetEmployelisty")
  const coupons = [1, 2, 3, 4, 5, 6, 7];
  //---------Delete-Coupon_api====//
  function Deleteemployee() {
    let data = JSON.stringify({
      id: props?.props?.params?.row?._id,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_COMPANY}/deleteemployee`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(response, "Deleteemployee");
          handleClose();
          GetEmployelist();
      })
      .catch(function (error) {});
  }
  //--------------//---------------//
  return (
    <>
      <Modal
        centered
        scrollable
        show={show}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <div className={`${m.logoutPopup}`}>
          <h3>Are you Sure you want to Delete this Employee ?</h3>
          <div className={`${m.btnsAlignments}`}>
            <button
              type="button"
              className={`btn ${m.actionBtn}`}
              onClick={() => {
                Deleteemployee();
              }}
            >
              Delete
            </button>
            <NavLink
              className={`btn ${m.cancelBtn}`}
              to=""
              onClick={handleClose}
            >
              Cancel
            </NavLink>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteEmployee;
