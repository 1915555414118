import React, { Fragment, useEffect, useState, useContext } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import cx from "./BookingRide.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Form, Col, Row, Button, Modal } from "react-bootstrap";
import { MdLocationOn, MdAccessTimeFilled, MdPadding } from "react-icons/md";
import { FaCalendar, FaDatabase } from "react-icons/fa";
import { MoreCar, PayPayment } from "../../../components/Website/Modals";
import {
  DriverRating,
  DriverTaxi,
  SelectTaxi,
} from "../../../components/Website";
import { usePlacesWidget } from "react-google-autocomplete";
import axios from "axios";
import FromLocation from "./FromLocation";
import { useSocket } from "../../../store/socket-context";
import { GoogleMap, MarkerF, DirectionsRenderer } from "@react-google-maps/api";
import io from "socket.io-client";
import { letData, letDropData } from "../Home/Home";
import moment from "moment";
import StripCmponent from "../checkoutform/CheckoutForm";
import GlobalContext from "../../../store/global-context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BookConfirm from "../../../components/Company/Modals/BookConfirm";

//----Socket -io-------//
// const ENDPOINT = `https://customer.taximo.ca`;
// const ENDPOINT = "https://taximo.ca/";
const ENDPOINT = "https://taximo.ca";
// const ENDPOINT = ""
// console.log(ENDPOINT, "ENDPOINT");
// const ENDPOINT = `http://taximo.ca:4040/`;

//---------//--------//

interface BookingRideLocations {
  pickupAddress: string;
  dropAddress: string;
  pickuplat: number;
  pickuplong: number;
  droplat: number;
  droplong: number;
  bookingStatus: string;
  type?: string;
}
let socket: any;
const BookingRide = ({ error, props }: any) => {
  const globalGtx = useContext(GlobalContext);
  //------------------//-----------------//

  let socketInstace = useSocket();
  const location: any = useLocation();
  // let data:any = localStorage.getItem("bookingData");
  let data = location;
  console.log(data, "homedara");
  const navigate = useNavigate();

  const hideButton = localStorage.getItem("userToken");
  const [mapboolean, setMapboolean] = useState(false);
  useEffect(() => {
    if (!hideButton) {
      // toast.error("Please login.");
      // setTimeout(() => {
      navigate("/login");
      // }, 100);
    }
    if (socketInstace.socket !== null) {
      console.log(socketInstace, "sockettt");
      socket = socketInstace.socket;
    } else {
      socket = io(ENDPOINT, { path: "/customer/socket.io" });
      // socket = io(ENDPOINT);
      socket.on("connect", function () {
        handleClick("authenticate", {
          login_token: localStorage.getItem("userToken"),
        });
        console.log("connectedooooo to server");
      });
      socket.on("disconnect", function () {
        console.log("disconnected to server");
      });
    }
  }, []);
  console.log(letData, "letData");
  // const [globalBooking, setGlobalBooking] = useState<BookingRideLocations>({
  //   pickupAddress:
  //     letDropData?.formatted_address === undefined
  //       ? ""
  //       : letDropData?.formatted_address,
  //   dropAddress:
  //     letDropData?.formatted_address === undefined
  //       ? ""
  //       : letDropData?.formatted_address,
  //   pickuplat: letData?.lat === undefined ? 0 : letData?.lat,
  //   pickuplong: letData?.long === undefined ? 0 : letData?.long,
  //   droplat: letDropData?.lat === undefined ? 0 : letDropData?.lat,
  //   droplong: letDropData?.long === undefined ? 0 : letDropData?.long,
  //   bookingStatus: "",
  // });

  const [globalBooking, setGlobalBooking] = useState<BookingRideLocations>({
    pickupAddress:
      letData?.formatted_address === undefined
        ? ""
        : letData?.formatted_address,
    dropAddress:
      letDropData?.formatted_address === undefined
        ? ""
        : letDropData?.formatted_address,
    pickuplat: letData?.lat === undefined ? 0 : letData?.lat,
    pickuplong: letData?.long === undefined ? 0 : letData?.long,
    droplat: letDropData?.lat === undefined ? 0 : letDropData?.lat,
    droplong: letDropData?.long === undefined ? 0 : letDropData?.long,
    // droplat: 0,
    // droplong: 0,
    bookingStatus: "",
  });

  const TransferValue = (latnew: any, longnew: any, formatted_address: any) => {
    setGlobalBooking((prev: BookingRideLocations) => {
      return {
        ...prev,
        droplat: latnew,
        droplong: longnew,

        dropAddress: formatted_address,
        type: "drop address",
      };
    });
    calculateRoute();
  };
  const [errMasg, setErrMasg] = useState(false);
  const ErrorTransfer = (event: boolean) => {
    setErrMasg(event);
  };
  useEffect(() => {
    if (mapboolean === true || letData?.lat !== undefined) {
      Getcategories();
    }
  }, [globalBooking]);

  useEffect(() => {
    calculateRoute();
  }, [globalBooking.pickupAddress, globalBooking.dropAddress]);

  useEffect(() => {
    if (
      (data?.pickuplat && data?.pickuplong) ||
      (data?.droplat && data?.droplong)
    ) {
      console.log(data, "dataaaaaaa");
      setGlobalBooking({ ...data });

      Getcategories(data);
    }
  }, [Getcategories, data]);
  const [map, setMap] = useState(/** @type google.maps.Map */ null);
  const [directionsResponse, setDirectionsResponse] = useState<any>(null);

  const { ref } = usePlacesWidget<any>({
    apiKey: "AIzaSyDQWdKXQ-8bh9GGLAj5Dc-hrJixxqQlRRg",
    libraries: ["places"],
    language: "en", // Ensure the language is set to English

    onPlaceSelected: (place: any) => {
      if (!place?.geometry) {
        console.error("Place selection did not return valid geometry.");
        return;
      }

      const formattedAddress = place?.formatted_address;
      console.log(
        encodeURIComponent(place?.formatted_address),
        decodeURIComponent(formattedAddress),

        decodeURIComponent(decodeURIComponent(formattedAddress)),
        "encodeURIComponent"
      ); // Encode the address to handle special characters
      const lat = place?.geometry?.location?.lat();
      const lng = place?.geometry?.location?.lng();

      if (!lat || !lng) {
        console.error("Invalid latitude or longitude.");
        return;
      }

      setGlobalBooking((prev: BookingRideLocations) => {
        return {
          ...prev,
          pickuplat: lat,
          pickuplong: lng,
          pickupAddress: formattedAddress, // Decode back to handle it in a user-friendly way
          type: "Pickup address",
        };
      });

      setMapboolean(true);
      calculateRoute();
    },

    options: {
      types: ["address"],
      // componentRestrictions: { country: "CA" }, // Restrict to a specific country if needed
      // types: ["establishment"], // Uncomment if you want to search for establishments
    },
  });

  const [isShow, setIsShow] = useState(true);
  async function calculateRoute() {
    if (
      globalBooking.pickupAddress === "" ||
      globalBooking.dropAddress === ""
    ) {
      return;
    }
    const directionsDisplay = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
    });
    setDirectionsResponse(directionsDisplay);
    setIsShow(false);
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results: any = await directionsService.route({
      origin: globalBooking.pickupAddress,
      destination: globalBooking.dropAddress,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setDirectionsResponse(results);
  }

  //----------//
  const [paymentshow, setpaymentShow] = useState(false);
  const [selectedTaxiId, setSelectedTaxiId] = useState("");
  const handlepaymentClose = () => setpaymentShow(false);
  const handlepaymentShow = () => {
    if (localData && !localStorage.getItem("otp")) {
      setpaymentShow(true);
    } else {
      console.log("dddddddddd");
      window.location.href = "/login";
    }
  };

  const [show, setShow] = useState(false);
  //------stripe--pop-up-----//
  const [stripeShow, setStripeShow] = useState(false);
  const [passengerDetails, setPassengerDetails] = useState(false);

  const PassengerDetailhandleClose = () => setPassengerDetails(false);
  const PassengerDetailhandleShow = () => setPassengerDetails(true);
  const StripehandleClose = () => setStripeShow(false);
  const StripehandleShow = () => setStripeShow(true);
  //-----------//------//

  const [numberShow, setNumberShow] = useState(false);
  const NumberhandleClose = () => setNumberShow(false);
  const NumberhandleShow = () => setNumberShow(true);

  const [noTaxiShow, setNoTaxiShow] = useState(false);
  const NoTaxiShowhandleClose = () => setNoTaxiShow(false);
  const NoTaxiShowhandleShow = () => setNoTaxiShow(true);

  const [searchNoTaxiShow, setSearchNoTaxiShow] = useState(false);
  const SearchNoTaxiShowhandleClose = () => setSearchNoTaxiShow(false);
  const SearchNoTaxiShowhandleShow = () => setSearchNoTaxiShow(true);

  const [driverRideShow, setDriverRideShow] = useState(false);
  const DriverRidehandleClose = () => setDriverRideShow(false);
  const DriverRidehandleShow = () => setDriverRideShow(true);

  const [cancelReasonShow, setCancelReasonShow] = useState(false);
  const CancelReasonhandleClose = () => setCancelReasonShow(false);
  const CancelReasonhandleShow = () => setCancelReasonShow(true);

  const [userReasonShow, setUserReasonShow] = useState(false);
  const CancelUserhandleClose = () => setUserReasonShow(false);
  const CancelUserhandleShow = () => setUserReasonShow(true);

  const [arrivedShow, setArrivedShowShow] = useState(false);
  const ArrivedhandleClose = () => setArrivedShowShow(false);
  const ArrivedhandleShow = () => setArrivedShowShow(true);

  const [rideStartedShow, setRideStartedShow] = useState(false);
  const RideStartedhandleClose = () => setRideStartedShow(false);
  const RideStartedhandleShow = () => setRideStartedShow(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [bookingStatus, setBookingStatus] = useState(
    data?.state?.data?.bookingStatus || "now"
  );
  function selectBooking(event: { target: { value: string } }) {
    setBookingStatus(event.target.value);
  }

  console.log(globalBooking, data?.bookingStatus, "bookingStatus");

  const [categoriesData, setCategoriesData] = useState<any>([]);
  const [categoriesselected, setSelected] = useState<any>("");

  const [numPassengers, setNumPassengers] = useState(0);
  const [passengerNames, setPassengerNames] = useState([""]);

  const handlePassengerChange = (index, event) => {
    const newPassengerNames = [...passengerNames];
    newPassengerNames[index] = event.target.value;
    setPassengerNames(newPassengerNames);
  };

  const addPassenger = () => {
    if (passengerNames.length < numPassengers) {
      setPassengerNames([...passengerNames, ""]);
    }
  };

  const removePassenger = (index) => {
    const newPassengerNames = [...passengerNames];
    newPassengerNames.splice(index, 1);
    setPassengerNames(newPassengerNames);
  };

  const handleNumPassengersChange = (event) => {
    const value = parseInt(event.target.value);

    console.log("categoriesselected><><><><><><categoriesselected", event);
    if (value <= categoriesselected) {
      setNumPassengers(value);
      setPassengerNames(passengerNames.slice(0, value));
    }
  };
  //-----------Socket-io----------//

  // console.log(
  //   "categoriesselected><><><><><><categoriesselected",
  //   categoriesselected
  // );
  // useEffect(() => {
  //   console.log("socket connection");

  //   socket = io(ENDPOINT, {
  //     path: "/customer/socket.io",
  //     // transports: ["websocket"], // Prioritize WebSocket over polling
  //     // reconnectionAttempts: 5,
  //     // timeout: 10000, // Set connection timeout to 10 seconds
  //   });

  //   socket.on("connect", function () {
  //     console.log("connected to server with session ID:", socket.id);
  //   });

  //   socket.on("disconnect", function () {
  //     console.log("disconnected from server");
  //   });

  //   socket.on("connect_error", (error) => {
  //     console.log("Connection Error:", error.message);
  //   });

  //   return () => {
  //     socket.disconnect(); // Clean up the socket connection on component unmount
  //   };
  // }, []);

  useEffect(() => {
    setSelectedTaxiId("");
  }, [bookingStatus]);
  const handleClick = (message: string, value: any) => {
    console.log(message, value, "ttt====>>>>>>>>");

    socket.emit(message, value, (response: any) => {
      console.log(response, "ttt====>>>>>>>>");

      if (response && response.status === 200) {
        // Handle successful response
        console.log("Response received successfully:", response);
      } else {
        // Handle error or unexpected response
        console.error("Error or unexpected response:", response);
      }
    });

    setRideAcceptedt(undefined);
    return socket;
  };

  //-------get_categories_withoutToken-//-----------//
  function Getcategories(data?: BookingRideLocations) {
    let Getdata = JSON.stringify({
      latitude: data?.pickuplat ? data?.pickuplat : globalBooking.pickuplat,
      longitude: data?.pickuplong ? data?.pickuplong : globalBooking.pickuplong,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_RIDES}/get_categories_withoutToken`,
      headers: {
        "Content-Type": "application/json",
      },
      data: Getdata,
    };
    axios(config)
      .then(function (response) {
        console.log(response, "iiiiiii");
        setCategoriesData(response?.data?.data);
      })
      .catch(function (error) {});
  }
  //-------Fare Estimate----------//
  const [fareEstimateData, setFareEstimateData] = useState<any>({});
  const [cityid, setcityid] = useState("");
  function GetFareEstimate(id: string, cityid: string, subzone_id: string) {
    setSelectedTaxiId(id);
    setcityid(cityid);
    let Faredata = JSON.stringify({
      subzone_id: subzone_id,
      category_id: id,
      pickup_lat: globalBooking.pickuplat,
      pickup_long: globalBooking.pickuplong,
      destination_lat: globalBooking.droplat,
      destination_long: globalBooking.droplong,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_RIDES}/fare_estimate_withoutToken`,
      headers: {
        "Content-Type": "application/json",
      },
      data: Faredata,
    };
    axios(config)
      .then(function (response) {
        let purchaseCouponFare = response.data.data;
        if (bookingStatus === "purchase") {
          purchaseCouponFare.total_fare =
            purchaseCouponFare.total_fare +
            purchaseCouponFare.total_fare *
              (purchaseCouponFare.coupon_percent / 100);
          purchaseCouponFare.total_fare.toFixed(2);
        }
        setFareEstimateData(purchaseCouponFare);
        setSelectedTaxiId(id);
        console.log(localStorage.getItem("userToken"), "localStorage.getItem");
        // if (localStorage.getItem("userToken")) {
        //   let responseReceived = false; // Flag to track if response is received

        //   // Set a timeout to refresh the page after 4 seconds if no response is received
        //   const timeoutId = setTimeout(() => {
        //     if (!responseReceived) {
        //       console.log(
        //         "No response received within 4 seconds. Refreshing page."
        //       );
        //       window.location.reload(); // Refresh the page
        //     }
        //   }, 10000); // 4 seconds

        //   socket.emit(
        //     "search_taxi",
        //     {
        //       language: "en",
        //       os: "windows",
        //       latitude: globalBooking.pickuplat,
        //       longitude: globalBooking.pickuplong,
        //       category_id: id,
        //       city_id: cityid,
        //     },
        //     (response: any) => {
        //       responseReceived = true; // Mark that response is received
        //       clearTimeout(timeoutId); // Clear the timeout if response is received
        //       console.log(response, "search taxi");

        //       // Handle response logic here if needed
        //     }
        //   );
        // }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  //------Get card list Api-------//
  const [loaderStatus, setloaderStatus] = useState("");
  const [cardlistData, setCardlistData] = useState<any[]>([]);
  // useEffect(() => {
  //   if (bookingStatus=="purchase") {
  //     console.log(fareEstimateData, 'fareEstimateData?.total_fare')
  //   }
  //   modifiedValue = fareEstimateData?.total_fare + (fareEstimateData?.total_fare * (fareEstimateData.coupon_percent / 100))
  // }, [fareEstimateData?.total_fare]);
  useEffect(() => {
    if (hideButton) {
      Getcard();
    }
  }, []);
  function Getcard() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_PAYMENTS}/list_card`,
      headers: {
        Authorization: localStorage.getItem("userToken")!,
      },
    };
    axios(config)
      .then(function (response) {
        if (response.data.data.length > 0) {
          const existingDefaultCard = response.data.data.some(
            (card: any) => card.is_default
          );

          const updatedCardList = response.data.data.map(
            (card: any, index: number) => ({
              ...card,
              is_default: existingDefaultCard ? card.is_default : index === 0, // Keep existing default, or set first card as default if none exist
            })
          );

          setCardlistData(updatedCardList);

          // setCardlistData(response.data.data);
        } else {
          setloaderStatus("No Card Available");

          setloaderStatus("");
        }
        let CardsData = response.data.data;

        globalGtx.StripePayment(CardsData);
      })
      .catch(function (error) {
        console.log(error, "errrr");
        if (error.code === "ERR_BAD_REQUEST") {
          localStorage.removeItem("userData");
          localStorage.removeItem("userToken");
          navigate("/login");
        }
      });
  }
  //-----------Get-Coupon Api-----------------//
  const [localdata, setlocaldata] = useState(localStorage.getItem("userData"));
  let localData: any = localdata;
  let profileData: any = JSON.parse(localData);
  const [dataCoupon, setDataCoupon] = useState<any>();
  function GetCoupon() {
    let Coupondata = JSON.stringify({
      category_id: selectedTaxiId,
      user_id: profileData._id,
      city_id: cityid,
      ride_type: "taxi",
      payment_type: "Card",
      // fare_estimate:
      //   fareEstimateData?.total_fare + fareEstimateData?.total_fare / 10,
      fare_estimate: fareEstimateData?.total_fare,
      schedule: bookingStatus === "now" ? false : true,

      passenger_details: passengerNames,
      passengers: numPassengers,
      location: {
        source: {
          address: globalBooking.pickupAddress,
          latitude: globalBooking.pickuplat,
          longitude: globalBooking.pickuplong,
          selected: false,
        },
        destination: {
          address: globalBooking.dropAddress,
          latitude: globalBooking.droplat,
          longitude: globalBooking.droplong,
          selected: false,
        },
      },
    });
    let TokenUser = localStorage.getItem("userToken");
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_WEBSITE}/preBook`,
      headers: {
        "Content-Type": "application/json",
        authorization: TokenUser,
      },
      data: Coupondata,
    };
    axios(config)
      .then(function (response) {
        if (response.status == 401) {
          toast.error("Something went wrong.");
          localStorage.removeItem("userData");
          localStorage.removeItem("userToken");
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        }
        setDataCoupon(response.data.ride_details);
        if (response.data.status == 200) {
          // CouponhandleShow();
          setSearchResponse(
            `Taxi Voucher Created : ${response.data.ride_details.coupon}`
          );
          SearchNoTaxiShowhandleShow();
          setTimeout(() => {
            SearchNoTaxiShowhandleClose();
            navigate("/user/coupons");
          }, 2000);
        }
      })
      .catch(function (error) {
        setSearchResponse("Something went wrong");
        SearchNoTaxiShowhandleShow();
        setTimeout(() => {
          SearchNoTaxiShowhandleClose();
        }, 1500);
      });

    //  setSearchResponse("Coupon Created");
    //  SearchNoTaxiShowhandleShow();
    //  setTimeout(() => {
    //    SearchNoTaxiShowhandleClose();
    //  }, 1500);
  }
  //-----------Get-User-list Api-----------------//
  useEffect(() => {
    GetUserList();
  }, []);
  useEffect(() => {
    console.log(cardlistData, "cardlistDatajjjjjjj");
  }, [cardlistData]);
  const [userData, setUserData] = useState<any>({});
  function GetUserList() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_WEBSITE}/getuser?id=${profileData?._id}`,
      headers: {
        Authorization: localStorage.getItem("userToken")!,
      },
    };
    axios(config)
      .then(function (response) {
        if (response.status == 401) {
          toast.error("Something went wrong.");
          localStorage.removeItem("userData");
          localStorage.removeItem("userToken");
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        }
        console.log(response, "GetUserList");
        let UserListData = response.data.data;
        setUserData(UserListData);
      })
      .catch(function (error) {});
  }
  //------------ride_accepted-Socket-------------//
  const [rideAccepted, setRideAcceptedt] = useState<any>();
  const [ridestatus, setridestatus] = useState<any>();

  const [rideAcceptedNew, setRideAcceptedtNew] = useState<any>();
  useEffect(() => {
    socket?.on("ride_accepted", (data: any) => {
      console.log(data, "ride_accepted");
      setridestatus("Dirver is on the way");
      SearchNoTaxiShowhandleClose();
      setRideAcceptedt(data);
      setRideAcceptedtNew(data);
    });
    socket?.off("ride_accepted", (data: any) => {
      console.log(data, "11111");
    });
  });
  //-----------//-----------//-------//
  //-------------decline-Ride-Socket-------------//
  const [cencleRideresponse, setCencleRideresponse] = useState("");
  useEffect(() => {
    socket?.on("ride_declined", (data: any) => {
      setCencleRideresponse("Your Ride Declined By Driver");
      DriverRidehandleShow();
      SearchNoTaxiShowhandleClose();
    });
    socket?.off("ride_declined", (data: any) => {});
  });
  //-----------//-----------//-------//
  //-----------request_cancelled------//
  const [cancelReason, setCancelReason] = useState("");
  useEffect(() => {
    socket?.on("request_cancelled", (data: any) => {
      setCancelReason("Your Ride Cancelled By Driver");
      CancelReasonhandleShow();
      SearchNoTaxiShowhandleClose();
      setRideAcceptedt(undefined);
      console.log(data, "cancelReason");
    });
    socket?.off("request_cancelled", (data: any) => {
      console.log(data, "noride");
    });
  });
  //------------//-------------------//
  //-----------ride_arrived------//
  const [arrived, setArrived] = useState("");
  useEffect(() => {
    socket?.on("ride_arrived", (data: any) => {
      setArrived("Driver Arrived");
      ArrivedhandleShow();
      setridestatus("Driver has arrived");
      SearchNoTaxiShowhandleClose();
      setTimeout(() => {
        ArrivedhandleClose();
      }, 2000);
      console.log(data, "arrived");
    });
    socket.off("ride_arrived", (data: any) => {
      console.log(data, "arrived");
    });
  });
  //------------//-------------------//
  //-----------ride_started------//
  const [rideStarted, setRideStarted] = useState("");
  const [ridedisabled, setRidedisabled] = useState(false);
  useEffect(() => {
    socket.on("ride_started", (data: any) => {
      setRideStarted("Ride Started");
      setridestatus("Ride started");
      SearchNoTaxiShowhandleClose();
      RideStartedhandleShow();
      setRidedisabled(true);
      setTimeout(() => {
        RideStartedhandleClose();
      }, 2000);
      console.log(data, "rideStarted");
    });
    socket.off("ride_started", (data: any) => {
      console.log(data, "rideStarted");
    });
  });
  //------------//-------------------//
  //-----------ride_finished------//
  const [ridefinished, setRidefinished] = useState<any>();
  useEffect(() => {
    socket.on("ride_finished", (dataNew: any) => {
      setRidefinished(dataNew);
      console.log(dataNew, "ridefinished");
    });
    socket.off("ride_finished", (dataNew: any) => {
      console.log(dataNew, "ridefinished");
    });
  });
  //------------//-------------------//
  const [clickPin, setClickPin] = useState<any>("");
  const handleiconShow = () => setShow(true);
  const handleOnLoad = (map: any) => {
    setMap(map);
  };

  //--Show Msg on Select Category--//No Taxi Available--//
  const [response, setResponse] = useState("");
  //--Show Msg on Select Category by Reeponse 200//No Taxi Available--//
  const [searchResponse, setSearchResponse] = useState("");
  const [loading, setLoading] = useState(false);

  //--Ride Schedule Time ----//
  const [fdate, setFDate] = useState<any>();
  const [fTime, setFTime] = useState<any>();
  //-----------Date------------//
  let todayDate = moment().subtract(0, "days").format("YYYY-MM-DD");
  let maxDate = moment().add(7, "days").format("YYYY-MM-DD");
  //-------------//--------//
  //-------------Time--------//
  let Todaytime = moment().format("HH:mm");
  let nowtime = Todaytime.split(":");
  //--------------//---------//
  const [timeErr, setTimeErr] = useState("");
  const [showh, setShowh] = useState(false);
  const handleClosee = () => setShowh(false);
  const handleShowh = () => setShowh(true);
  const dateGTR = new Date();
  const MaxTime = dateGTR.setHours(dateGTR.getHours() + 48);
  const DeductAmount = fareEstimateData?.total_fare_estimate;
  // fareEstimateData?.total_fare + fareEstimateData?.total_fare / 10;
  console.log(fareEstimateData?.total_fare, "updatedCardList");
  console.log(cardlistData, bookingStatus, "cardListData");
  function selectTempCard(cardId: string) {
    const updatedCardList = cardlistData.map((card) => ({
      ...card,
      is_default: card._id === cardId, // Set the selected card as default
    }));

    setCardlistData(updatedCardList);
  }
  const handleGetCoupan = (event) => {
    PassengerDetailhandleClose();
    GetCoupon();
  };

  return (
    <>
      <ToastContainer />
      <section className={`${cx.bookingSection}`}>
        <div className={`${cx.bookingForm}`}>
          {ridefinished !== undefined && (
            <Row>
              <Col lg={12}>
                <div>
                  <DriverRating
                    ridefinished={ridefinished}
                    rideAcceptedNew={rideAcceptedNew}
                  />
                </div>
              </Col>
            </Row>
          )}
          {ridefinished == undefined && (
            <>
              <div className={`${cx.bookingScroll}`}>
                <Row>
                  {/*GoogleMap-Mobile-responsive*/}
                  <div className={`${cx.mobileMapSection}`}>
                    <GoogleMap
                      onLoad={handleOnLoad}
                      mapContainerClassName={`map-view-mobile ${
                        clickPin ? "clickable" : ""
                      }`}
                      mapContainerStyle={{
                        height: "calc(100vh - 80px)",
                        width: "100%",
                        display: "block",
                        pointerEvents: clickPin,
                      }}
                      zoom={20}
                      // options={{ mapId:`20da50ab6ed57367`}}
                      center={{
                        lat:
                          globalBooking.pickuplat !== 0 &&
                          globalBooking.droplat !== 0
                            ? (globalBooking.pickuplat +
                                globalBooking.droplat) /
                              2
                            : globalBooking.pickuplat,
                        lng:
                          globalBooking.pickuplong !== 0 &&
                          globalBooking.droplong !== 0
                            ? (globalBooking.pickuplong +
                                globalBooking.droplong) /
                              2
                            : globalBooking.pickuplong,
                      }}
                    >
                      {isShow && (
                        <MarkerF
                          position={{
                            lat: globalBooking.pickuplat,
                            lng: globalBooking.pickuplong,
                          }}
                        ></MarkerF>
                      )}

                      {directionsResponse && (
                        <>
                          <DirectionsRenderer
                            directions={directionsResponse}
                            options={{
                              polylineOptions: {
                                strokeColor: "#000000",
                                strokeOpacity: 0.9,
                                strokeWeight: 4,
                              },
                              suppressMarkers: true,
                            }}
                          />
                          <MarkerF
                            position={{
                              lat: globalBooking.pickuplat,
                              lng: globalBooking.pickuplong,
                            }}
                          />
                          <MarkerF
                            position={{
                              lat: globalBooking.droplat,
                              lng: globalBooking.droplong,
                            }}
                            onLoad={(marker: any) => {
                              const customIcon = () =>
                                Object.assign({
                                  path: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
                                  fillColor: "#4CBB17",
                                  fillOpacity: 1,
                                  strokeColor: "#4CBB17",
                                  scale: 1.7,
                                  anchor: { x: 12, y: 24 },
                                });

                              marker.setIcon(customIcon());
                              return marker;
                            }}
                          />
                        </>
                      )}
                    </GoogleMap>
                  </div>
                  {/*GoogleMap-Mobile-responsive */}
                  <Col lg={12} className={`${cx.title} text-left`}>
                    <div className={`${cx.heading}`}>Fare estimate</div>
                    <p>
                      Sample fares are estimates only and do not reflect
                      variations due to discounts, traffic delays or other
                      factors.
                    </p>
                  </Col>
                  <Col lg={12}>
                    <div className={`${st.formBox} ${st.iconForm}`}>
                      <span className={`${st.icon}`}>
                        <MdLocationOn />
                      </span>
                      <Form.Control
                        type="text"
                        placeholder="From"
                        ref={ref}
                        defaultValue={
                          data?.pickupAddress || globalBooking?.pickupAddress
                        }
                      />
                    </div>
                  </Col>

                  <FromLocation
                    TransferValue={TransferValue}
                    ErrorTransfer={ErrorTransfer}
                    errMasg={errMasg}
                    DropData={data?.dropAddress || globalBooking?.dropAddress}
                  />
                  {rideAccepted == undefined && (
                    <Col lg={12}>
                      <div className={`${cx.bookingType}`}>
                        <label className={`${cx.radio}`}>
                          <input
                            type="radio"
                            name="bookingType"
                            checked={bookingStatus === "now"}
                            value="now"
                            onChange={selectBooking}
                          />
                          <span className={`${cx.checkmark}`}></span>
                          Request a Taxi
                        </label>
                        <label className={`${cx.radio}`}>
                          <input
                            type="radio"
                            name="bookingType"
                            checked={bookingStatus === "schedule"}
                            value="schedule"
                            onChange={selectBooking}
                          />
                          <span className={`${cx.checkmark}`}></span>
                          Reserve a Taxi
                        </label>
                        <label className={`${cx.radio}`}>
                          <input
                            type="radio"
                            name="bookingType"
                            checked={bookingStatus === "purchase"}
                            value="purchase"
                            onChange={selectBooking}
                          />
                          <span className={`${cx.checkmark}`}></span>
                          Purchase Voucher
                        </label>
                      </div>
                    </Col>
                  )}
                  {bookingStatus === "schedule" && (
                    <Fragment>
                      <Col lg={6}>
                        <div className={`${st.formBox} ${st.iconForm}`}>
                          <span
                            className={`${st.icon}`}
                            style={{ fontSize: "14px" }}
                          >
                            <FaCalendar />
                          </span>
                          <Form.Control
                            type="date"
                            placeholder="To"
                            min={todayDate}
                            max={maxDate}
                            onChange={(e: any) => {
                              console.log(e, "dateconsole");
                              setFDate(e.target.value);
                            }}
                          />
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className={`${st.formBox} ${st.iconForm}`}>
                          <span
                            className={`${st.icon}`}
                            style={{ fontSize: "17px" }}
                          >
                            <MdAccessTimeFilled />
                          </span>
                          <Form.Control
                            type="time"
                            placeholder="To"
                            onChange={(e: any) => {
                              setTimeErr("");
                              setFTime(e.target.value);
                            }}
                          />
                          <p style={{ color: "#fbbf15" }}>{timeErr}</p>
                        </div>
                      </Col>
                    </Fragment>
                  )}

                  <Col lg={12}>
                    {rideAccepted === undefined && (
                      <div
                        className={`${cx.taxiList} d-flex justify-content-between flex-wrap `}
                      >
                        <SelectTaxi
                          globalBooking={globalBooking}
                          ErrorTransfer={ErrorTransfer}
                          categoriesData={categoriesData}
                          GetFareEstimate={GetFareEstimate}
                          selectedTaxiId={selectedTaxiId}
                          setSelected={setSelected}
                        />
                      </div>
                    )}
                    {rideAccepted !== undefined && (
                      <div
                        className={`${cx.taxiList} d-flex justify-content-between`}
                      >
                        <DriverTaxi
                          rideAccepted={rideAccepted}
                          ridestatus={ridestatus}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
              {globalBooking.pickupAddress != "" &&
                globalBooking.dropAddress != "" &&
                selectedTaxiId != "" &&
                rideAccepted === undefined && (
                  <div className={`${st.formBox} ${cx.btnFix}`}>
                    <ul>
                      <li>
                        <span>Fare Estimate :</span>
                        {bookingStatus === "purchase" ? (
                          <span>
                            $
                            {
                              fareEstimateData?.total_fare
                              // // fareEstimateData?.total_fare +
                              // //   fareEstimateData?.total_fare / 10
                              // fareEstimateData?.total_fare +
                              //   (fareEstimateData?.total_fare *
                              //     fareEstimateData?.total_fare_estimate) /
                              //     100
                            }
                          </span>
                        ) : (
                          <span>${fareEstimateData?.total_fare}</span>
                        )}
                      </li>
                      <li>
                        <span>
                          {
                            cardlistData?.find(
                              (event): any => event?.is_default
                            )?.number
                          }
                        </span>
                        <span>
                          <NavLink to="#" onClick={handlepaymentShow}>
                            Add Card
                          </NavLink>
                        </span>
                      </li>
                    </ul>

                    {cardlistData.length > 0 ? (
                      <Button
                        className={`btn ${st.submitBtn} ${st.fullWidth}`}
                        disabled={loading}
                        onClick={() => {
                          setLoading(true);
                          if (bookingStatus === "purchase") {
                            // StripehandleShow();
                            setNumPassengers(0);
                            setPassengerNames([]);
                            PassengerDetailhandleShow();
                            // GetCoupon()
                          } else if (fdate === todayDate) {
                            let mytime = fTime.split(":");

                            if (
                              `${nowtime[0]}${nowtime[1]}` >
                              `${mytime[0]}${mytime[1]}`
                            ) {
                              setTimeErr("No Ride this Time");
                            } else {
                              const datefunc =
                                +new Date(`${fdate} ${fTime}`) / 1000;
                              if (localStorage.getItem("userToken")) {
                                console.log(
                                  localStorage.getItem("userToken"),
                                  "localStorage.getItem"
                                );
                                handleClick("authenticate", {
                                  login_token:
                                    localStorage.getItem("userToken"),
                                }).emit(
                                  "book_ride",
                                  {
                                    category_id: selectedTaxiId,
                                    ride_type: "taxi",
                                    city_id: cityid,
                                    fare_estimate: fareEstimateData?.total_fare,
                                    payment_type: "Card",
                                    schedule:
                                      bookingStatus === "now" ? false : true,
                                    ride_on:
                                      bookingStatus === "now"
                                        ? ""
                                        : datefunc.toString(),
                                    card: {
                                      brand: cardlistData?.find(
                                        (event): any => event?.is_default
                                      )?.brand,
                                      name: cardlistData?.find(
                                        (event): any => event?.is_default
                                      )?.name,
                                      card_id: cardlistData?.find(
                                        (event): any => event?.is_default
                                      )?.card_id,
                                      number: cardlistData?.find(
                                        (event): any => event?.is_default
                                      )?.number,
                                      is_default: false,
                                      _id: cardlistData?.find(
                                        (event): any => event?.is_default
                                      )?._id,
                                      selected: false,
                                    },
                                    os: "windows",
                                    source: {
                                      address: encodeURIComponent(
                                        globalBooking.pickupAddress
                                      ),
                                      latitude: globalBooking.pickuplat,
                                      longitude: globalBooking.pickuplong,
                                      selected: false,
                                    },
                                    destination: {
                                      address: encodeURIComponent(
                                        globalBooking.dropAddress
                                      ),
                                      latitude: globalBooking.droplat,
                                      longitude: globalBooking.droplong,
                                      selected: false,
                                    },
                                    language: "en",
                                  },
                                  (response: any) => {
                                    setLoading(false);
                                    console.log(response, "book_ride");
                                    if (response.status == 203) {
                                      setResponse(response.reply);
                                      NoTaxiShowhandleShow();
                                      socket.emit(
                                        "search_taxi",
                                        {
                                          language: "en",
                                          os: "windows",
                                          latitude: globalBooking.pickuplat,
                                          longitude: globalBooking.pickuplong,
                                          category_id: selectedTaxiId,
                                          city_id: cityid,
                                        },
                                        (response: any) => {
                                          console.log(response, "bb22");
                                        }
                                      );
                                    } else if (response.status == 200) {
                                      setSearchResponse(response.reply);
                                      SearchNoTaxiShowhandleShow();
                                      setTimeout(() => {
                                        SearchNoTaxiShowhandleClose();
                                      }, 1500);
                                      if (bookingStatus !== "now") {
                                        window.location.href = "/login";
                                      }
                                    }
                                  }
                                );
                              }
                            }
                          } else {
                            const datefunc =
                              +new Date(`${fdate} ${fTime}`) / 1000;
                            console.log(
                              localStorage.getItem("userToken"),
                              "localStorage.getItem"
                            );
                            if (localStorage.getItem("userToken")) {
                              console.log("in the bloack");
                              handleClick("authenticate", {
                                login_token: localStorage.getItem("userToken"),
                              }).emit(
                                "book_ride",
                                {
                                  category_id: selectedTaxiId,
                                  ride_type: "taxi",
                                  city_id: cityid,
                                  fare_estimate: fareEstimateData?.total_fare,
                                  payment_type: "Card",
                                  schedule:
                                    bookingStatus === "now" ? false : true,
                                  ride_on:
                                    bookingStatus === "now"
                                      ? ""
                                      : datefunc.toString(),
                                  card: {
                                    brand: cardlistData?.find(
                                      (event): any => event?.is_default
                                    )?.brand,
                                    name: cardlistData?.find(
                                      (event): any => event?.is_default
                                    )?.name,
                                    card_id: cardlistData?.find(
                                      (event): any => event?.is_default
                                    )?.card_id,
                                    number: cardlistData?.find(
                                      (event): any => event?.is_default
                                    )?.number,
                                    is_default: false,
                                    _id: cardlistData?.find(
                                      (event): any => event?.is_default
                                    )?._id,
                                    selected: false,
                                  },
                                  os: "windows",
                                  source: {
                                    address: encodeURIComponent(
                                      globalBooking.pickupAddress
                                    ),
                                    latitude: globalBooking.pickuplat,
                                    longitude: globalBooking.pickuplong,
                                    selected: false,
                                  },
                                  destination: {
                                    address: encodeURIComponent(
                                      globalBooking.dropAddress
                                    ),
                                    latitude: globalBooking.droplat,
                                    longitude: globalBooking.droplong,
                                    selected: false,
                                  },
                                  language: "en",
                                },

                                (response: any) => {
                                  setLoading(false);
                                  console.log(response, "book_ride");
                                  if (response.status == 203) {
                                    setResponse(response.reply);
                                    NoTaxiShowhandleShow();

                                    socket.emit(
                                      "search_taxi",
                                      {
                                        language: "en",
                                        os: "windows",
                                        latitude: globalBooking.pickuplat,
                                        longitude: globalBooking.pickuplong,
                                        category_id: selectedTaxiId,
                                        city_id: cityid,
                                      },
                                      (response: any) => {
                                        console.log(response, "bb22");
                                      }
                                    );
                                  } else if (response.status == 200) {
                                    setSearchResponse(response.reply);
                                    SearchNoTaxiShowhandleShow();
                                    // setTimeout(() => {
                                    //   SearchNoTaxiShowhandleClose();
                                    // }, 1500);
                                    if (bookingStatus !== "now") {
                                      setTimeout(() => {
                                        SearchNoTaxiShowhandleClose();
                                      }, 1500);
                                      window.location.href = "/user/rides";
                                    }
                                  }
                                }
                              );
                            }
                          }
                        }}
                      >
                        {loading ? "Wait..." : "Confirm"}
                      </Button>
                    ) : (
                      <Button
                        className={`btn ${st.submitBtn} ${st.fullWidth}`}
                        disabled
                        // onClick={handleShowh}
                      >
                        Confirm
                      </Button>
                    )}
                  </div>
                )}
              {/* {rideAccepted !== undefined && (
                <div
                  className={`${st.formBox} ${cx.btnFix} position-absolute `}
                  style={{ bottom: "-155px" }}
                >
                  <Row>
                    <Col lg={6} md={6}>
                      {" "}
                      <Button
                        className={`btn ${st.submitBtn} ${st.fullWidth}`}
                        onClick={() => {
                          CancelUserhandleShow();
                        }}
                        disabled={ridedisabled}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col lg={6} md={6}>
                      <Button
                        className={`btn ${st.submitBtn} ${st.fullWidth}`}
                        onClick={() => {
                          NumberhandleShow();
                        }}
                      >
                        Contact
                      </Button>
                    </Col>
                  </Row>
                </div>
              )} */}
            </>
          )}
        </div>

        {/*GoogleMap-Web-responsive*/}
        <div className={`${cx.mapSection}`}>
          <GoogleMap
            onLoad={handleOnLoad}
            mapContainerStyle={{
              height: "calc(100vh - 80px)",
              width: "100%",
              display: "block",
              pointerEvents: clickPin,
            }}
            zoom={20}
            // options={{ mapId:`20da50ab6ed57367`}}
            center={{
              lat:
                globalBooking.pickuplat !== 0 && globalBooking.droplat !== 0
                  ? (globalBooking.pickuplat + globalBooking.droplat) / 2
                  : globalBooking.pickuplat,
              lng:
                globalBooking.pickuplong !== 0 && globalBooking.droplong !== 0
                  ? (globalBooking.pickuplong + globalBooking.droplong) / 2
                  : globalBooking.pickuplong,
            }}
          >
            {isShow && (
              <MarkerF
                position={{
                  lat: globalBooking.pickuplat,
                  lng: globalBooking.pickuplong,
                }}
              ></MarkerF>
            )}

            {directionsResponse && (
              <>
                <DirectionsRenderer
                  directions={directionsResponse}
                  options={{
                    polylineOptions: {
                      strokeColor: "#000000",
                      strokeOpacity: 0.9,
                      strokeWeight: 4,
                    },
                    suppressMarkers: true,
                  }}
                />
                <MarkerF
                  position={{
                    lat: globalBooking.pickuplat,
                    lng: globalBooking.pickuplong,
                  }}
                />
                <MarkerF
                  position={{
                    lat: globalBooking.droplat,
                    lng: globalBooking.droplong,
                  }}
                  onLoad={(marker: any) => {
                    const customIcon = () =>
                      Object.assign({
                        path: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
                        fillColor: "#4CBB17",
                        fillOpacity: 1,
                        strokeColor: "#4CBB17",
                        scale: 1.7,
                        anchor: { x: 12, y: 24 },
                      });

                    marker.setIcon(customIcon());
                    return marker;
                  }}
                />
              </>
            )}
          </GoogleMap>
        </div>
        {/*GoogleMap-Web-responsive*/}
      </section>

      <MoreCar show={show} handleClose={handleClose} />

      <PayPayment
        paymentshow={paymentshow}
        handlepaymentClose={handlepaymentClose}
        fareEstimateData={fareEstimateData}
        Getcard={Getcard}
        GetUserList={GetUserList}
        selectTempCard={selectTempCard}
        cardlistData={cardlistData}
        loaderStatus={loaderStatus}
      />
      <Modal
        centered
        scrollable
        show={numberShow}
        className={`${cx.modalPopup}`}
        onHide={NumberhandleClose}
      >
        <Modal.Header closeButton>
          {/* <Modal.Title> Phone Number</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={12}>
              <Form.Group className={`${cx.formField}`}>
                <Form.Label>Driver Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  disabled
                  defaultValue={rideAccepted?.driver_mobile}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        scrollable
        show={noTaxiShow}
        className={`${cx.modalPopup}`}
        onHide={NoTaxiShowhandleClose}
      >
        <Modal.Body>
          <div
            style={{ color: "525252", fontSize: "20px", fontFamily: "Poppins" }}
            className="d-flex justify-content-center"
          >
            {response}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className={`${st.formBox} mb-0 mt-3 text-center`}>
            <Button
              className={`btn ${st.submitBtn} ${st.fullWidth}`}
              onClick={() => {
                NoTaxiShowhandleClose();
              }}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        scrollable
        show={searchNoTaxiShow}
        className={`${cx.modalPopup}`}
        onHide={SearchNoTaxiShowhandleClose}
      >
        <Modal.Body>
          <div
            style={{
              color: "525252",
              fontSize: "20px",
              padding: "20px",
              fontFamily: "Poppins",
              fontWeight: "600",
              backgroundColor: "#fbbf15",
            }}
            className="d-flex justify-content-center"
          >
            {searchResponse}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        scrollable
        show={driverRideShow}
        className={`${cx.modalPopup}`}
        onHide={DriverRidehandleClose}
      >
        <Modal.Body>
          <div
            style={{ color: "525252", fontSize: "20px", fontFamily: "Poppins" }}
            className="d-flex justify-content-center"
          >
            {cencleRideresponse}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className={`${st.formBox} mb-0 mt-3 text-center`}>
            <Button
              className={`btn ${st.submitBtn} ${st.fullWidth}`}
              onClick={() => {
                DriverRidehandleClose();
              }}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        scrollable
        show={cancelReasonShow}
        className={`${cx.modalPopup}`}
        onHide={CancelReasonhandleClose}
      >
        <Modal.Body>
          <div
            style={{ color: "525252", fontSize: "20px", fontFamily: "Poppins" }}
            className="d-flex justify-content-center"
          >
            {cancelReason}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className={`${st.formBox} mb-0 mt-3 text-center`}>
            <Button
              className={`btn ${st.submitBtn} ${st.fullWidth}`}
              onClick={() => {
                CancelReasonhandleClose();
              }}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        scrollable
        show={userReasonShow}
        className={`${cx.modalPopup}`}
        onHide={CancelUserhandleClose}
      >
        <Modal.Body>
          <div
            style={{ color: "525252", fontSize: "27px", fontFamily: "Poppins" }}
            className="d-flex justify-content-center"
          >
            Ride cancellation Will cost you $5 ! Do you want to proceed ?
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div>
            <Button
              style={{
                margin: "12px",
                backgroundColor: "#FBBF15",
                borderColor: "#FBBF15",
              }}
              onClick={() => {
                CancelUserhandleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ backgroundColor: "#FBBF15", borderColor: "#FBBF15" }}
              onClick={() => {
                handleClick("cancel_ride", {
                  ride_id: rideAccepted?.ride_id,
                });
              }}
            >
              OK
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        scrollable
        show={arrivedShow}
        className={`${cx.modalPopup}`}
        onHide={ArrivedhandleClose}
      >
        <Modal.Body>
          <div
            style={{ color: "525252", fontSize: "20px", fontFamily: "Poppins" }}
            className="d-flex justify-content-center"
          >
            {arrived}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        scrollable
        show={rideStartedShow}
        className={`${cx.modalPopup}`}
        onHide={RideStartedhandleClose}
      >
        <Modal.Body>
          <div
            style={{ color: "525252", fontSize: "20px", fontFamily: "Poppins" }}
            className="d-flex justify-content-center"
          >
            {rideStarted}
          </div>
        </Modal.Body>
      </Modal>

      {/* stripe pop-up */}
      <Modal
        centered
        scrollable
        show={stripeShow}
        className={`${cx.modalPopup}`}
        onHide={StripehandleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Stripe Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="mb-3" lg={12}>
              Total amount is :{" "}
              <span style={{ fontWeight: "600", textTransform: "uppercase" }}>
                $
                {fareEstimateData?.total_fare +
                  fareEstimateData?.total_fare / 10}
              </span>
            </Col>
            <Col lg={12}>
              <StripCmponent
                DeductAmount={DeductAmount}
                StripehandleClose={StripehandleClose}
                GetCoupon={GetCoupon}
                dataCoupon={dataCoupon}
                userData={userData}
              />
              {/* <StripCmponent userData={userData}/> */}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* ////////----// */}
      {/* PassengerDetails */}
      <Modal
        centered
        scrollable
        show={passengerDetails}
        onHide={PassengerDetailhandleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Passenger Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={12}>
              <Form.Group controlId="numPassengers">
                <Form.Label>Number of Passengers</Form.Label>
                <Form.Control
                  type="number"
                  value={numPassengers}
                  onChange={handleNumPassengersChange}
                  max={categoriesselected}
                  min={1}
                />
              </Form.Group>
              <Form.Label style={{ marginTop: "7px" }}>
                Names of Passengers
              </Form.Label>
              {Array.from({ length: passengerNames.length }).map((_, index) => (
                <div key={index} className="mb-2" style={{ display: "flex" }}>
                  <Form.Control
                    style={{
                      width: "80%",
                      marginTop: "5px",
                    }}
                    type="text"
                    placeholder={`Passenger ${index + 1}`}
                    value={passengerNames[index] || ""}
                    onChange={(event) => handlePassengerChange(index, event)}
                  />
                  {index > 0 && (
                    <Button
                      variant="danger"
                      style={{
                        margin: " 8px 10px 7px 3px ",
                        padding: "5px;",
                        fontSize: "12px",
                      }}
                      onClick={() => removePassenger(index)}
                    >
                      Remove
                    </Button>
                  )}
                </div>
              ))}
            </Col>

            <Col lg={12}>
              {passengerNames.length < numPassengers && (
                <Button
                  variant="primary"
                  onClick={addPassenger}
                  style={{
                    margin: " 8px 10px 7px 1px",
                    width: "20%",
                  }}
                >
                  Add
                </Button>
              )}
            </Col>

            <Col lg={12}>
              <Button
                className={`btn ${st.submitBtn} ${st.fullWidth}`}
                style={{
                  background: "#fbbf15",
                  width: "100%",
                  border: "#fbbf15",
                  marginTop: "20px",
                }}
                onClick={handleGetCoupan}
              >
                Create Coupon
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* PassengerDetails */}
      <BookConfirm
        show={showh}
        handleClose={handleClosee}
        globalBooking={globalBooking}
        fdate={fdate}
        fTime={fTime}
        selectedTaxiId={selectedTaxiId}
        categoriesselected={categoriesselected}
      />
    </>
  );
};
export default BookingRide;
