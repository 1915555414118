import React, { Fragment, useEffect, useState } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Modal, Button, Row, Tab, Nav, Col, Form } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { Logo, qrCode } from "../../../assets/images";
import axios from "axios";

const ShowReceiptM = (props: any) => {
  let { receiptShow, handlereceiptShowClose,showReceipt } = props;
  const date = new Date(showReceipt?.last_bill?.date_time * 1000);
  const newdate = date.toLocaleString("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

  return (
    <>
      <Modal
        centered
        scrollable
        show={receiptShow}
        className={`${m.modalCts} ${m.paymentTab}`}
      >
        <Modal.Header className="border-0">
          <Modal.Title>Closing Report</Modal.Title>
          <button
            className={`${m.closeIcon}`}
            title="Close"
            onClick={handlereceiptShowClose}
          >
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body
          style={{ padding: "0px", overflowX: "hidden", background: "#2a2a2a" }}
        >
          <Row>
            <Col lg={12}>
              <div className={` ${m.receiptReport}  `}>
                <ul>
                  <li>
                    <span>Company Name</span>
                    <span>Taximo</span>
                  </li>
                  <li>
                    <span>invoice id</span>
                    <span>{showReceipt?.invoice_no}</span>
                  </li>
                  <li>
                    <span>Taxi No :</span>
                    <span>{showReceipt?.basic?.vehicle?.plateno}</span>
                  </li>
                  <li>
                    <span>Driver Mob No. :</span>
                    <span>{showReceipt?.basic?.provider_id?.mobile}</span>
                  </li>
                  <li>
                    <span>GST NO. :</span>
                    <span>{showReceipt?.basic?.provider_id?.other_info?.gst_no}</span>
                  </li>
                  <li>
                    <span>PST NO. :</span>
                    <span>{showReceipt?.basic?.provider_id?.other_info?.pst_no}</span>
                  </li>
                  <li>
                    <span>Date :</span>
                    <span>{newdate}</span>
                  </li>
                  <li>
                    <span>Payment Type:</span>
                    <span>{showReceipt?.basic?.payment_type}</span>
                  </li>
                  <li>
                    <span>Waiting Time</span>
                    <span>0</span>
                  </li>
                  <li>
                    <span>Ride Fare:</span>
                    <span>$ {showReceipt?.payment?.base_fare}</span>
                  </li>
                  <li>
                    <span>GST:</span>
                    <span>$ {showReceipt?.payment?.gst_value}</span>
                  </li>
                  <li>
                    <span>PST:</span>
                    <span>$ {showReceipt?.payment?.pst_value}</span>
                  </li>
                  <li>
                    <span>Total Fare:</span>
                    <span>$ {showReceipt?.payment?.fare_charged}</span>
                  </li>
                </ul>
                <div className={`${m.qrCodeImg}`}>
                  {/* <img src={showReceipt?.basic?.url} alt="" /> */}
                  <img src={qrCode} alt="" />
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShowReceiptM;
