import React, { useEffect, useState } from "react";
import m from "./Modal.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import axios from "axios";
import { MdClose } from "react-icons/md";

const EditPopup = (props: any) => {
  let { editshow, handleCloseEdit, CardDelete, GetCouponlist, daTaCoupon } =
    props;
  console.log(daTaCoupon, "daTaCouponEdit");
  const coupons = [1, 2, 3, 4, 5, 6, 7];
  const navigate = useNavigate();
  //-----Edit-Coupon-Api------//
  const [fieldError, setFieldError] = useState<any>("");
  const fillFields = (key: any, value: any) => {
    //console.log(key,value,"e.target.files")
    setFields((prev: any) => {
      return { ...prev, [key]: value };
    });
    if (fieldError !== undefined && fieldError[key]) {
      fieldError[key] = "";
    }
  };
  const checkFields = (fields: any) => {
    const fieldErr: any = {};
    Object.keys(fields).map((e: any) => {
      if (fields[e] === "") {
        fieldErr[e] = (
          <p style={{ color: "#fbbf15", textAlign: "center" }}>
            {" "}
            This field is required{" "}
          </p>
        );
      }
    });
    if (Object.keys(fieldErr).length === 0) {
      EditCoupon(fields);
    } else {
      setFieldError(fieldErr);
    }
  };
  const [fields, setFields] = useState<any>({
    //title: title === "" ? daTaCoupon?.title : title,
    title: "",
    amount: "",
    startDate: "",
    endDate: "",
    companyId: "",
  });
  useEffect(() => {
    setFields({
      title: props?.props?.params?.row?.title,
      amount: props?.props?.params?.row?.amount,
      startDate: props?.props?.params?.row?.startDate,
      endDate: props?.props?.params?.row?.endDate,
      couponId: props?.props?.params?.row?._id,
    });
  }, []);
  function EditCoupon(Newfields: any) {
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_API_COMPANY}/updatecoupon`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(Newfields),
    };
    axios(config)
      .then(function (response) {
        handleCloseEdit();
        GetCouponlist();
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  return (
    <>
      <Modal
        centered
        scrollable
        show={editshow}
        onHide={handleCloseEdit}
        className={`${m.modalCts}`}
      >
        <Modal.Header>
          <Modal.Title>Edit Coupon</Modal.Title>
          <button
            className={`${m.closeIcon}`}
            title="Close"
            onClick={handleCloseEdit}
          >
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={12}>
              <div className={`${m.formBox}`}>
                <label>Coupon Title</label>
                <Form.Control
                  type="text"
                  placeholder=""
                  defaultValue={fields?.title}
                  onChange={(e: any) => fillFields("title", e.target.value)}
                />
              </div>
              {fieldError?.title}
            </Col>
            <Col lg={6}>
              <div className={`${m.formBox}`}>
                <label>Coupon Amount ($)</label>
                <Form.Control
                  type="text"
                  placeholder=""
                  defaultValue={fields?.amount}
                  className={`${m.removeArrow}`}
                  onChange={(e: any) => fillFields("amount", e.target.value)}
                />
              </div>
              {fieldError?.amount}
            </Col>

            <Col lg={6}>
              <div className={`${m.formBox}`}>
                <label>Start Date</label>
                <Form.Control
                  type="date"
                  placeholder=""
                  defaultValue={fields?.startDate}
                  className={`${m.removeArrow}`}
                  onChange={(e: any) => fillFields("startDate", e.target.value)}
                />
              </div>
              {fieldError?.startDate}
            </Col>
            <Col lg={6}>
              <div className={`${m.formBox}`}>
                <label>End Date</label>
                <Form.Control
                  type="date"
                  placeholder=""
                  defaultValue={fields?.endDate}
                  className={`${m.removeArrow}`}
                  onChange={(e: any) => fillFields("endDate", e.target.value)}
                />
              </div>
              {fieldError?.endDate}
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button className={`${m.submit}`} onClick={() => checkFields(fields)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditPopup;
