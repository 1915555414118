import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import tb from "../../../assets/stylesheet/datatable.module.scss";
import { useParams } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import { Status } from "../../../components/Company/Forms";
import DeleteEmployee from "../../../components/Website/Modals/DeleteEmployee";
import EditEmployee from "../../../components/Website/Modals/EditEmployee";

const StatusButton = (props: any) => {
  let { GetEmployelist } = props;
  console.log(GetEmployelist, "propsdtaa");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //------------//-----------------//
  const [editshow, setEditshow] = useState(false);
  const handleCloseEdit = () => setEditshow(false);
  const handleShowEdit = () => setEditshow(true);

  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          <li>
            <button
              className={`btn ${tb.edit}`}
              title="Edit"
              onClick={handleShowEdit}
            >
              <MdEdit />
            </button>
          </li>
          <li>
            <button
              className={`btn ${tb.delete}`}
              title="Delete"
              onClick={handleShow}
            >
              <AiFillDelete />
            </button>
          </li>
          {/* <li>
            <Status />
          </li> */}
        </ul>
      </div>
      <DeleteEmployee
        show={show}
        handleClose={handleClose}
        GetEmployelist={GetEmployelist}
        props={props}
      />
      <EditEmployee
        editshow={editshow}
        handleCloseEdit={handleCloseEdit}
        GetEmployelist={GetEmployelist}
        props={props}
      />
    </>
  );
};

export default function UserDataGrid(props: any) {
  let { daTaEmployee, GetEmployelist } = props;

  const columns = [
    { field: "id", headerName: "Sr No", flex: 1, minWidth: 60 },
    { field: "name", headerName: "Employee Name", flex: 1, minWidth: 60 },
    { field: "email", headerName: "Employee Email", flex: 1, minWidth: 120 },
    {
      field: "designation",
      headerName: "Employee Designation",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => (
        <StatusButton params={params} GetEmployelist={GetEmployelist} />
      ),
    },
  ];

  console.log(daTaEmployee, "daTaEmployee");
  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={daTaEmployee}
        autoHeight
        hideFooterPagination={true}
        rowHeight={48}
        headerHeight={48}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
  );
}
