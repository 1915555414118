import React, { useEffect, useState, Fragment } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import tb from "../../../assets/stylesheet/datatable.module.scss";
import { NavLink } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
import { Status } from "../../../components/Company/Forms";
import { DeletePopup } from "../../../components/Website/Modals";
import EditPopup from "../../../components/Website/Modals/EditPopup";

const StatusButton = (props: any) => {
  let { GetCouponlist,daTaCoupon } = props;
  console.log(daTaCoupon,"daTaCouponEditNEw")
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //------------//-----------------//
  const [editshow, setEditshow] = useState(false);
  const handleCloseEdit = () => setEditshow(false);
  const handleShowEdit = () => setEditshow(true);

  return (
    <>
      <div>
        <ul className={`${tb.actionTable}`}>
          <li>
            <button 
            className={`btn ${tb.edit}`} 
            title="Edit"  
            type="button"
            onClick={handleShowEdit}
            >
              <MdEdit />
            </button>
          </li>
          <li>
            <button
              type="button"
              className={`btn ${tb.delete}`}
              title="Delete"
              onClick={handleShow}
            >
              <AiFillDelete
              />
            </button>
          </li>
          {/* <li>
            <Status />
          </li> */}
        </ul>
      </div>
      <DeletePopup show={show} handleClose={handleClose} GetCouponlist={GetCouponlist}  props={props}/>
      <EditPopup editshow={editshow} handleCloseEdit={handleCloseEdit} GetCouponlist={GetCouponlist} props={props} />
    </>
  );
};



export default function UserDataGrid(props: any) {
  let { daTaCoupon,GetCouponlist } = props;
  console.log(daTaCoupon, "daTaEmployee");
  const columns = [
    { field: "id", headerName: "Sr No", flex: 1, minWidth: 60 },
    { field: "title", headerName: "Coupon Title", flex: 1, minWidth: 200 },
    { field: "amount", headerName: "Amount ($)", flex: 1, minWidth: 120 },
    { field: "startDate", headerName: "Start Date", flex: 1, minWidth: 150 },
    { field: "endDate", headerName: "End Date", flex: 1, minWidth: 150 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => <StatusButton params={params} GetCouponlist={GetCouponlist}/>,
    },
  ];
  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        rows={daTaCoupon}
        autoHeight
        hideFooterPagination={true}
        rowHeight={48}
        headerHeight={48}
        checkboxSelection
        disableSelectionOnClick
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
  );
}
