import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import cx from "./BecomeCompany.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Container, Form, Col, Row, Button } from "react-bootstrap";
import { Banner1 } from "../../../assets/images";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const BecomeCompany = (props: any) => {
  //---react-toastify-----//
  const navigate = useNavigate();
  const notify = (message: String) =>
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);
  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 5000);
    }
  }, [onetimenotify]);

  //--------Registration-Api-------//

  const [fieldError, setFieldError] = useState<any>("");
  const [tick, setTick] = useState(true);
  const [errtick, setErrtick] = useState("");
  const regex = "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$";
  const fillFields = (key: any, value: any) => {
    //console.log(key,value,"e.target.files")
    setFields((prev: any) => {
      return { ...prev, [key]: value };
    });
    if (fieldError !== undefined && fieldError[key]) {
      fieldError[key] = "";
    }
  };
  const checkFields = (fields: any) => {
    const fieldErr: any = {};
    Object.keys(fields).map((e: any) => {
      if (fields[e] === "") {
        fieldErr[e] = (
          <p style={{ color: "#fbbf15", textAlign: "center" }}>
            {" "}
            This field is required{" "}
          </p>
        );
      }
    });
    if (Object.keys(fieldErr).length === 0) {
      //CompanyRegistration(fields);
      if (fields.email.match(regex)) {
        CompanyRegistration(fields);
      } else {
        fieldErr.email = (
          <p style={{ color: "#738801", textAlign: "center" }}>
            {" "}
            Invalid Email{" "}
          </p>
        );
        setFieldError(fieldErr);
      }
    } else {
      setFieldError(fieldErr);
    }
  };
  const [fields, setFields] = useState({
    name: "",
    companyType: "",
    numberOfEmployee: "",
    registrationNo: "",
    country: "",
    city: "",
    pCode: "",
    address: "",
    Vdocs: "",
    email: "",
  });

  function CompanyRegistration(fields: any) {
    if (tick == true) {
      setErrtick("Please Select Checkbox");
    } else {
      setErrtick("");
      let entries = Object.keys(fields);
      let formData = new FormData();
      for (let i = 0; i < entries.length; i++) {
        formData.append(entries[i], fields[entries[i]]);
      }
      let config = {
        method: "post",
        url: ` ${process.env.REACT_APP_API_COMPANY}/register`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };
      axios(config)
        .then(function (response) {
          // console.log(response, "CompanyRegistration");
          setTick(false);
          if (response.status == 200) {
            setTimenotify(response.data.reply);
            setOnetimenotify(true);
            setTimeout(() => {
              navigate("/login");
            }, 1500);
          } else if (response.status == 203) {
            setTimenotify(response.data.reply);
            setOnetimenotify(true);
            setTick(false);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
  //------------//-----------------//
  return (
    <>
      <ToastContainer />
      <section className={`${cx.bookingSection}`}>
        <div className={`${cx.bannersection}`}>
          <img src={Banner1} />
          <div className={`${cx.bannerContent}`}>
            <h2>Become a Company</h2>
          </div>
        </div>
      </section>

      <section className={`${st.sectionPadding}`}>
        <Container>
          <Row>
            <Col lg={9} className={`m-auto`}>
              <div className={`${cx.loginForm}`}>
                <div className={`${cx.contentBody}`}>
                  <h3>Become a Company</h3>
                  <Form>
                    <Row>
                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <label>Enter Company Name</label>
                          <Form.Control
                            type="text"
                            onChange={(e: any) =>
                              fillFields("name", e.target.value)
                            }
                          />
                        </div>
                        {fieldError?.name}
                      </Col>
                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <label>Company Type</label>

                          <Form.Select
                            aria-label="Default select example"
                            onChange={(e: any) =>
                              fillFields("companyType", e.target.value)
                            }
                          >
                            <option>--- Select ---</option>
                            <option value="private">Private</option>
                            <option value="private">Public/Corporation</option>
                            <option value="private">LLC</option>
                            <option value="private">Dispatching</option>
                          </Form.Select>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <label>Number of employees </label>
                          <Form.Control
                            type="number"
                            min={0}
                            onChange={(e: any) =>
                              fillFields("numberOfEmployee", e.target.value)
                            }
                          />
                        </div>
                        {fieldError?.numberOfEmployee}
                      </Col>
                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <label>Company Registration Number </label>
                          <Form.Control
                            type="number"
                            min={0}
                            onChange={(e: any) =>
                              fillFields("registrationNo", e.target.value)
                            }
                          />
                        </div>
                        {fieldError?.registrationNo}
                      </Col>
                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <label>Country</label>
                          <Form.Control
                            type="text"
                            onChange={(e: any) =>
                              fillFields("country", e.target.value)
                            }
                          />
                        </div>
                        {fieldError?.country}
                      </Col>
                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <label>City</label>
                          <Form.Control
                            type="text"
                            onChange={(e: any) =>
                              fillFields("city", e.target.value)
                            }
                          />
                        </div>
                        {fieldError?.city}
                      </Col>
                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <label>Postal Code </label>
                          <Form.Control
                            type="text"
                            min={0}
                            onChange={(e: any) =>
                              fillFields("pCode", e.target.value)
                            }
                          />
                        </div>
                        {fieldError?.pCode}
                      </Col>
                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <label>Address </label>
                          <Form.Control
                            type="text"
                            onChange={(e: any) =>
                              fillFields("address", e.target.value)
                            }
                          />
                        </div>
                        {fieldError?.address}
                      </Col>
                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <label>Email </label>
                          <Form.Control
                            type="email"
                            onChange={(e: any) =>
                              fillFields("email", e.target.value)
                            }
                          />
                        </div>
                        {fieldError?.email}
                      </Col>
                      <Col lg={6}>
                        <div className={`${st.formBox}`}>
                          <label>Verification Document</label>
                          <Form.Control
                            type="file"
                            className="px-4"
                            onChange={(e: any) =>
                              fillFields("Vdocs", e.target.files[0])
                            }
                          />
                        </div>
                        {fieldError?.Vdocs}
                      </Col>
                      <Col lg={12}>
                        <div className={`${st.formBox}`}>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="form1Example3"
                              onChange={() => {
                                setTick((prevValue) => !prevValue);
                                setErrtick("");
                              }}
                            />
                            <label className="form-check-label">
                              You are agree our{" "}
                              <NavLink
                                target="_blank"
                                to="/terms-and-conditions"
                              >
                                Terms & Conditions
                              </NavLink>
                            </label>
                          </div>
                          <p style={{ color: "#fbbf15" }}> {errtick}</p>
                        </div>
                      </Col>
                      <Col lg={5} className="m-auto">
                        <div className={`${st.formBox} mb-0`}>
                          <Button
                            onClick={() => checkFields(fields)}
                            className={`btn ${st.submitBtn} ${st.fullWidth}`}
                          >
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default BecomeCompany;
