import React, { Fragment, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import st from "../../../../assets/stylesheet/companyStyle.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import cx from "./ScheduleBooking.module.scss";
import {
  Card,
  Button,
  Row,
  Col,
  Modal,
  Form,
  Dropdown,
  Tab,
  Tabs,
  Nav,
} from "react-bootstrap";
import axios from "axios";
export default function ScheduleBooking() {
  const navigate = useNavigate();
  //------List-Rides_-----//
  const [loaderStatus, setloaderStatus] = useState("");
  const [ridesList, setRidesList] = useState<any[]>([]);
  const [localdata, setlocaldata] = useState(
    localStorage.getItem("companyData")
  );
  let localData: any = localdata;
  let profileData: any = JSON.parse(localData);
  function getRides(type: any) {
    let data = JSON.stringify({
      list_type: type,
      companyId: profileData?._id,
      page: "1",
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_RIDES}/ridelist`,
      headers: {
        Authorization: localStorage.getItem("companyToken")!,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        //console.log(response, "getRides");
        setRidesList(response.data.data);
        if (response.data.data.length == 0) {
          setloaderStatus("No Ride Details Available");
        } else {
          setloaderStatus("");
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  }
  //---------///
  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.pageTitleRow}`}>
            <div className={`${st.rowTitleLeft}`}>
              <h5>Schedule Booking</h5>
            </div>
            <div className={`${st.rowTitleRight}`}>
              <button
                className={`btn ${st.themeBtn}`}
                onClick={() => {
                  navigate("/company/Allride", {
                    state: {
                      data: {
                        RideStatus: "ScheduleRide",
                      },
                    },
                  });
                }}
              >
                Schedule Ride Booking
              </button>
            </div>
          </div>
        </div>

        <div className={`${st.pageWrapperInside}`}>
          <Row>
            {/* {ridesList.length > 0 &&
              ridesList?.map((item: any) => {
                  const date = new Date(item.start_on * 1000);
                  const newdate = date.toLocaleString("en-US", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  });
                  return (
                    <>
                      <Col lg={6}>
                        <div className={`${cx.rideBox}`}>
                          <div className={`${cx.background}`}>
                            <Row>
                              <Col lg={8}>
                                <ul className={`${cx.location}`}>
                                  <li>{item?.source?.address}</li>
                                  <li>{item?.destination?.address}</li>
                                </ul>
                                <small>{newdate}</small>
                              </Col>
                              <Col lg={4} className="text-end">
                                <h5 className="mb-0">
                                  $ {item?.fare_estimate}
                                </h5>
                                <NavLink
                                  className={`btn ${cx.rideBtn}`}
                                  to="#"
                                  style={{ background: "#fbbf15" }}
                                >
                                  Schedule
                                </NavLink>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </>
                  );
                
              })}
            {loaderStatus && (
              <div className="d-flex justify-content-center">
                {loaderStatus}
              </div>
              
            )} */}
            <div className={`${cx.contentInside} ${cx.rideHistory}`}>
              {/* <Tab.Container defaultActiveKey="radeTab1" > */}
              <Tab.Container>
                <Row>
                  <Col sm={3}>
                    <Nav variant="pills" className={`flex-column`}>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="radeTab1"
                          onClick={() => {
                            getRides("upcoming");
                          }}
                        >
                          Upcoming
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="radeTab2"
                          onClick={() => {
                            getRides("past");
                          }}
                        >
                          Past
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={9}>
                    <Tab.Content>
                      <Tab.Pane
                        eventKey="radeTab1"
                        className={`${cx.filterMenu}`}
                      >
                        <Tabs>
                          <Tab eventKey="scheduled" title="Upcoming">
                            <Row>
                              <Col lg={12}>
                                {ridesList.length > 0 &&
                                  ridesList?.map((item: any) => {
                                    const date = new Date(item.start_on * 1000);
                                    const newdate = date.toLocaleString(
                                      "en-US",
                                      {
                                        year: "numeric",
                                        month: "numeric",
                                        day: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      }
                                    );
                                    return (
                                      <>
                                        <Col lg={6}>
                                          <div className={`${cx.rideBox}`}>
                                            <div className={`${cx.background}`}>
                                              <Row>
                                                <Col lg={8}>
                                                  <ul
                                                    className={`${cx.location}`}
                                                  >
                                                    <li>
                                                      {item?.source?.address}
                                                    </li>
                                                    <li>
                                                      {
                                                        item?.destination
                                                          ?.address
                                                      }
                                                    </li>
                                                  </ul>
                                                  <small>{newdate}</small>
                                                </Col>
                                                <Col
                                                  lg={4}
                                                  className="text-end"
                                                >
                                                  <h5 className="mb-0">
                                                    $ {item?.fare_estimate}
                                                  </h5>
                                                  <NavLink
                                                    className={`btn ${cx.rideBtn}`}
                                                    to="#"
                                                    style={{
                                                      background: "#fbbf15",
                                                    }}
                                                  >
                                                    Schedule
                                                  </NavLink>
                                                </Col>
                                              </Row>
                                            </div>
                                          </div>
                                        </Col>
                                      </>
                                    );
                                  })}
                                {loaderStatus && (
                                  <div className="d-flex justify-content-center">
                                    {loaderStatus}
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </Tab>
                        </Tabs>
                      </Tab.Pane>

                      <Tab.Pane
                        eventKey="radeTab2"
                        className={`${cx.filterMenu}`}
                      >
                        <Tabs>
                          <Tab eventKey="finished" title="Past">
                            <Row>
                              <Col lg={12}>
                                {ridesList.length > 0 &&
                                  ridesList?.map((item: any) => {
                                    const date = new Date(item.start_on * 1000);
                                    const newdate = date.toLocaleString(
                                      "en-US",
                                      {
                                        year: "numeric",
                                        month: "numeric",
                                        day: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      }
                                    );
                                    return (
                                      <>
                                        <Col lg={6}>
                                          <div className={`${cx.rideBox}`}>
                                            <div className={`${cx.background}`}>
                                              <Row>
                                                <Col lg={8}>
                                                  <ul
                                                    className={`${cx.location}`}
                                                  >
                                                    <li>
                                                      {item?.source?.address}
                                                    </li>
                                                    <li>
                                                      {
                                                        item?.destination
                                                          ?.address
                                                      }
                                                    </li>
                                                  </ul>
                                                  <small>{newdate}</small>
                                                </Col>
                                                <Col
                                                  lg={4}
                                                  className="text-end"
                                                >
                                                  <h5 className="mb-0">
                                                    $ {item?.fare_estimate}
                                                  </h5>
                                                  <NavLink
                                                    className={`btn ${cx.rideBtn}`}
                                                    to="#"
                                                    style={{
                                                      background: "#fbbf15",
                                                    }}
                                                  >
                                                    Schedule
                                                  </NavLink>
                                                </Col>
                                              </Row>
                                            </div>
                                          </div>
                                        </Col>
                                      </>
                                    );
                                  })}
                                {loaderStatus && (
                                  <div className="d-flex justify-content-center">
                                    {loaderStatus}
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </Tab>
                          {/* <Tab eventKey="cancelled" title="Cancelled">
                                  <Row>
                                  <Col lg={12}>
                                      </Col>
                                  </Row>
                                </Tab> */}
                        </Tabs>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </Row>
        </div>
      </section>
    </>
  );
}
