import React, { Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import cx from "./AllRide.module.scss";
import st from "../../../assets/stylesheet/style.module.scss";
import { Container, Form, Col, Row } from "react-bootstrap";
import { MdLocationOn, MdAccessTimeFilled } from "react-icons/md";
import { usePlacesWidget } from "react-google-autocomplete";
import axios from "axios";
interface BookingDetails {
  from?: string;
  to?: string;
}

const FromLocation = (props: any) => {
  let { TransferValue, DropData, ErrorTransfer, errMasg } = props;
  //----------//
  const { ref } = usePlacesWidget<any>({
    apiKey: "AIzaSyDQWdKXQ-8bh9GGLAj5Dc-hrJixxqQlRRg",
    libraries: ["places"],
    // language: "fr",
    language: "en",

    onPlaceSelected: (place: any) => {
      // console.log(place, "place");
      ErrorTransfer(false);
      TransferValue(
        place.geometry.location.lat(),
        place.geometry.location.lng(),
        place.formatted_address
      );
    },
    options: {
      types: ["address"],
      // componentRestrictions: { country: "CA" },
      // types: ["establishment"],
    },
  });
  //----//

  //-------------//---------------//
  return (
    <>
      <Col lg={12}>
        <div className={`${st.formBox} ${st.iconForm}`}>
          <span className={`${st.icon}`}>
            <MdLocationOn />
          </span>
          <Form.Control
            type="text"
            placeholder="To"
            ref={ref}
            defaultValue={DropData}
          />
          {errMasg == true && (
            <p style={{ color: "#fbbf15" }}>Please Enter Your Drop Loction</p>
          )}
        </div>
      </Col>
    </>
  );
};

export default FromLocation;
