import React, { useState } from "react";
import m from "./Modal.module.scss";
import st from "../../../assets/stylesheet/companyStyle.module.scss";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AddEmployee = (props: any) => {
  const navigate = useNavigate();
  let { show, handleClose, GetEmployelist } = props;
  //-----Add-employee-------//

  const [localdata, setlocaldata] = useState(
    localStorage.getItem("companyData")
  );
  let localData: any = localdata;
  let profileData: any = JSON.parse(localData);
  let id = profileData?._id;
  const [fieldError, setFieldError] = useState<any>("");
  const regex = "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$";
  const fillFields = (key: any, value: any) => {
    //console.log(key,value,"e.target.files")
    setFields((prev: any) => {
      return { ...prev, [key]: value };
    });
    if (fieldError !== undefined && fieldError[key]) {
      fieldError[key] = "";
    }
  };
  const checkFields = (fields: any) => {
    const fieldErr: any = {};
    Object.keys(fields).map((e: any) => {
      if (fields[e] === "") {
        fieldErr[e] = (
          <p style={{ color: "#fbbf15", textAlign: "center" }}>
            {" "}
            This field is required{" "}
          </p>
        );
      }
    });
    if (Object.keys(fieldErr).length === 0) {
      //ADDEmploye(fields)
      if (fields.email.match(regex)) {
        console.log(fields, "newgg");
        ADDEmploye(fields);
      } else {
        fieldErr.email = (
          <p style={{ color: "#738801", textAlign: "center" }}>
            {" "}
            Invalid Email{" "}
          </p>
        );
        setFieldError(fieldErr);
      }
    } else {
      setFieldError(fieldErr);
    }
  };
  const [fields, setFields] = useState({
    name: "",
    email: "",
    designation: "",
    password: "",
    companyId: id,
  });
  const [msgName, setMsgName] = useState<any>("");
  function ADDEmploye(Newfields: any) {
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_API_COMPANY}/registeremployee`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(Newfields),
    };
    axios(config)
      .then(function (response) {
        // console.log(response, "ADDEmploye");
        if (response.status == 200) {
          handleClose();
          GetEmployelist();
          setTimeout(() => {
            navigate("/company/employee");
          }, 1000);
        } else {
          setMsgName("Employee Is Already Exists");
          setTimeout(() => {
            navigate("/company/employee");
          }, 1000);
        }
        setMsgName("");
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  //---------//------------//
  return (
    <>
      <Modal
        centered
        scrollable
        show={show}
        onHide={handleClose}
        className={`${m.modalCts}`}
      >
        <Modal.Header>
          <Modal.Title>Add Employee</Modal.Title>
          <button
            className={`${m.closeIcon}`}
            title="Close"
            onClick={handleClose}
          >
            <MdClose />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={12}>
              <div className={`${st.formBox}`}>
                <label>Employee Name </label>
                <Form.Control
                  type="text"
                  placeholder=""
                  onChange={(e: any) => fillFields("name", e.target.value)}
                />
              </div>
              {fieldError?.name}
            </Col>
            <Col lg={12}>
              <div className={`${st.formBox}`}>
                <label>Employee Email</label>
                <Form.Control
                  type="email"
                  placeholder=""
                  onChange={(e: any) => fillFields("email", e.target.value)}
                />
              </div>
              {fieldError?.email}
            </Col>
            <Col lg={12}>
              <div className={`${st.formBox}`}>
                <label>Employee Password</label>
                <Form.Control
                  type="password"
                  placeholder=""
                  onChange={(e: any) => fillFields("password", e.target.value)}
                />
              </div>
              {fieldError?.password}
            </Col>
            <Col lg={12}>
              <div className={`${st.formBox}`}>
                <label>Employee Designation</label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e: any) =>
                    fillFields("designation", e.target.value)
                  }
                >
                  <option>--- Select ---</option>
                  <option value="Manager">Manager</option>
                </Form.Select>
              </div>
              {fieldError?.designation}
            </Col>
            <p style={{ color: "#fbbf15", textAlign: "center" }}>{msgName}</p>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button className={`${m.submit}`} onClick={() => checkFields(fields)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddEmployee;
