import React, { Fragment, useCallback, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import st from "../../../../assets/stylesheet/companyStyle.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import cx from "./RideBooking.module.scss";
import { Card, Button, Row, Col, Modal, Form, Dropdown } from "react-bootstrap";
import axios from "axios";

export default function RideBooking() {
  //--------get coupon-----//
  const hideButton = localStorage.getItem("companyToken");
  const [localdata, setlocaldata] = useState(
    localStorage.getItem("companyData")
  );
  let localData: any = localdata;
  let profileData: any = JSON.parse(localData);
  const [loaderStatus, setloaderStatus] = useState("");
  const [cardlistData, setCardlistData] = useState<any[]>([]);

  useEffect(() => {
    if (hideButton) {
      GetRideDetails();
    }
  }, []);
  function GetRideDetails() {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_COMPANY}/ridesall?companyId=${profileData?._id}`,
      headers: {
        Authorization: localStorage.getItem("companyToken")!,
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(response, "GetRideDetails");
        if (response.data.data.length == 0) {
          setloaderStatus("No Card Available");
        } else {
          setloaderStatus("");
        }
        let CardsData = response.data.data;
        setCardlistData(CardsData);
      })
      .catch(function (error) {});
  }
  //----------//-----------//
  const navigate = useNavigate();
  return (
    <>
      <section className={`${st.pageWrapper}`}>
        <div className={`${st.pageTitle}`}>
          <div className={`${st.pageTitleRow}`}>
            <div className={`${st.rowTitleLeft}`}>
              <h5>Ride Booking</h5>
            </div>
            <div className={`${st.rowTitleRight}`}>
              <button
                className={`btn ${st.themeBtn}`}
                onClick={() => {
                  navigate("/company/Allride", {
                    state: {
                      data: {
                        RideStatus: "RequestRide",
                      },
                    },
                  });
                }}
              >
                Book a Ride
              </button>
            </div>
          </div>
        </div>

        <div className={`${st.pageWrapperInside}`}>
          <Row>
            {cardlistData &&
              cardlistData.length > 0 &&
              cardlistData?.map((item: any) => {
                const date = new Date(item.created * 1000);
                  const newdate = date.toLocaleString("en-US", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  });
                return (
                  <>
                    <Col lg={6}>
                      <div className={`${cx.rideBox}`}>
                        <div className={`${cx.background}`}>
                          <Row>
                            <Col lg={8}>
                              <ul className={`${cx.location}`}>
                                <li>{item?.location?.source?.address}</li>
                                <li> {item?.location?.destination?.address}</li>
                              </ul>
                              <small>12-06-2023 - 11:02 PM</small>
                            </Col>
                            <Col lg={4} className="text-end">
                              <h5 className="mb-0">$ {item?.payment?.fare_estimate}</h5>
                              <NavLink
                                className={`btn ${cx.rideBtn}`}
                                to="#"
                                style={{ background: "#3d4755" }}
                              >
                                Today
                              </NavLink>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </>
                );
              })}
            {loaderStatus && (
              <div className="d-flex justify-content-center">
                {loaderStatus}
              </div>
            )}
          </Row>
        </div>
      </section>
    </>
  );
}
